import React, { useState } from "react";
import { Button } from "../../../../../_shared/Button";
import { InputForm } from "../../../../../_shared/InputField";
import { ToggleSwitch } from "../../../../../_shared/ToggleSwitch";

export const Insurances = (props: {
    onClose: Function;
    setIsValidated: Function;
    isReadOnly: boolean;
    setData: Function;
    data: any;
}) => {
    const { onClose, setIsValidated, isReadOnly, setData, data } = props;

    const [civilResponsability, setCivilResponsability] = useState(
        (data && data.assurances.civilResponsability) || null
    );
    const [mutuelle, setIsMutuelle] = useState(
        (data && data.assurances.mutuelle) || false
    );
    const [motifs, setMotifs] = useState(
        (data && data.assurances.motifs) || ""
    );

    const checkInformation = () => {
        return true;
    };

    const _onValidation = () => {
        const res = checkInformation();
        if (res) {
            setData({
                ...data,
                ...{
                    assurances: {
                        civilResponsability,
                        mutuelle,
                        motifs,
                    },
                },
            });
            onClose();
        } else {
            setIsValidated(false);
        }
    };
    return (
        <>
            {" "}
            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <ToggleSwitch
                        checked={civilResponsability}
                        onChange={() =>
                            setCivilResponsability(!civilResponsability)
                        }
                        disabled={isReadOnly}
                        label="Avez-vous une responsabilité civile ?"
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <ToggleSwitch
                        checked={mutuelle}
                        disabled={isReadOnly}
                        onChange={() => setIsMutuelle(!mutuelle)}
                        label="Avez-vous une complémentaire santé ?"
                    />
                </div>
            </div>
            {!mutuelle && (
                <InputForm
                    name="Si non, motifs ?"
                    type="textarea"
                    set={setMotifs}
                    value={motifs}
                    readOnly={isReadOnly}
                />
            )}
            {!isReadOnly && (
                <div className="flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            )}
        </>
    );
};
