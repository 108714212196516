import { request } from "../utils/axios-utils";

const namespace = "/api/user";

export const getCourts = (addToast: any) => {
    return request({
        addToast,
        url: namespace + "/courts",
        method: "get",
    });
};

export const getMjpmi = (addToast: any) => {
    return request({
        addToast,
        url: namespace + "/mjpmi",
        method: "get",
    });
};
