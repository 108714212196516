import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
    addEmailToMandat,
    uploadInformations,
} from "../../../../../_api/Mandat";
import { getSettingsCRG } from "../../../../../_api/User";
import { Accordeon } from "../../../../../_shared/Accordeon";
import { AlertMessage } from "../../../../../_shared/AlertMessage";
import { Button } from "../../../../../_shared/Button";
import { InputForm } from "../../../../../_shared/InputField";
import { ToggleSwitch } from "../../../../../_shared/ToggleSwitch";
import Loader from "../../../../layout/Loader";
import { DettesEtCreances } from "./Dettes";
import { Documents } from "./Documents";
import DropZoneArea from "./DropzoneArea";
import { Insurances } from "./Insurances";
import { Logement } from "./Logement";
import { Patrimoine } from "./Patrimoine";
import { SituationMaritale } from "./SituationMaritale";
import { SituationPro } from "./SituationPro";
import { Tribunal } from "./Tribunal";

function formatDate(date: any) {
    const day = String(date.getDate()).padStart(2, "0"); // Extrait le jour et le formate sur 2 chiffres
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Extrait le mois (0-11) et l'incrémente pour le format (1-12)
    const year = date.getFullYear(); // Extrait l'année
    return `${year}-${month}-${day}`; // Combine les trois parties
}

export const DepotCRG = (props: { readOnly?: any; data?: any }) => {
    const { readOnly, data } = props;

    const [simplifyUpload, setSimplifyUpload] = useState(
        data ? data.isSimplify : false
    );
    const [bulkUploadDoc, setBulkUploadDoc] = useState(
        (data && data.simplify_doc) || []
    );
    const [numeroRG, setNumeroRG] = useState(
        data ? data.control.rg_number : null
    );
    const [email, setEmail] = useState("");
    const [portalis, setNumeroPortalis] = useState(
        data ? data.control.portalis_number : null
    );
    const [startDate, setStartDate] = useState(
        data ? formatDate(new Date(data.start_period)) : null
    );
    const [endDate, setEndDate] = useState(
        data ? formatDate(new Date(data.end_period)) : null
    );

    const { addToast } = useToasts();
    const { depot_id }: { depot_id?: string } = useParams();

    const [isOpenLogement, setIsOpenLogement] = useState(data ? true : false);
    const [isOpenTribunal, setIsOpenTribunal] = useState(data ? true : false);
    const [isOpenDocuments, setIsOpenDocuments] = useState(data ? true : false);
    const [isOpenInsurance, setIsOpenInsurance] = useState(data ? true : false);
    const [isOpenSituationPro, setIsOpenSituationPro] = useState(
        data ? true : false
    );
    const [isOpenSituationMaritale, setIsOpenSituationMaritale] = useState(
        data ? true : false
    );
    const [isOpenDebt, setIsOpenDebt] = useState(data ? true : false);
    const [isOpenPatrimoine, setIsOpenPatrimoine] = useState(
        data ? true : false
    );

    const [isValidatedDocuments, setIsValidatedDocuments] = useState(false);
    const [isValidatedInsurance, setIsValidatedInsurance] = useState(false);
    const [isValidatedSituationPro, setIsValidatedSituationPro] =
        useState(false);
    const [isValidatedSituationMaritale, setIsValidatedSituationMaritale] =
        useState(false);
    const [isValidatedDebts, setIsValidatedDebts] = useState(false);
    const [isValidatedPatrimoine, setIsValidatedPatrimoine] = useState(false);

    const [isValidatedLogement, setIsValidatedLogement] = useState(false);
    const [isValidatedTribunal, setIsValidatedTribunal] = useState(false);

    const [isValidatedForm, setIsValidatedForm] = useState(false);
    const [settings, setSettings] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [allData, setAllData] = useState(
        data || {
            assurances: {
                civilResponsability: null,
                mutuelle: false,
                css: false,
                motifs: null,
            },
            debts: {
                isDebt: false,
                isConsumerDebt: false,
                isCreancy: false,
                isDebtDoc: [],
                isConsumerDebtDoc: [],
                isCreancyDoc: [],
            },
            documents: {
                AvisTax: [],
                AvisInsurance: [],
                bankStatements: [],
                crg: [],
                invoices: [],
            },
            logement: {
                propritary: null,
                mrhBuilding: null,
                mrh: false,
                propritaryDoc: [],
                SDFDoc: [],
                establishmentDoc: [],
            },
            patrimoine: {
                biens: [],
                biensMobiliers: [],
            },
            situation_marital: {
                maritalType: null,
                askedPension: false,
                yearDeath: null,
                isDivorced: false,
                eatPension: false,
                prestaComp: false,
                nbChild: 0,
            },
            situation_pro: {
                actifType: null,
                proType: null,
                rsa: false,
                aah: false,
                pension: false,
                workAccident: false,
                rsaDoc: [],
                aahDoc: [],
                pensionDoc: [],
                workAccidentDoc: [],
            },
            tribunal: {
                isJudgeContacted: false,
                isJudgeContactedDoc: [""],
            },
            isSimplify: false,
            simplify_doc: [],
        }
    );

    const close = (name: any, isSubmit = false) => {
        switch (name) {
            case "documents":
                setIsOpenDocuments(!isOpenDocuments);
                if (isSubmit) {
                    setIsValidatedDocuments(true);
                }
                break;
            case "insurance":
                setIsOpenInsurance(!isOpenInsurance);
                if (isSubmit) {
                    setIsValidatedInsurance(true);
                }
                break;
            case "pro":
                setIsOpenSituationPro(!isOpenSituationPro);
                if (isSubmit) {
                    setIsValidatedSituationPro(true);
                }
                break;
            case "tribunal":
                setIsOpenTribunal(!isOpenTribunal);
                if (isSubmit) {
                    setIsValidatedTribunal(true);
                }
                break;
            case "logement":
                setIsOpenLogement(!isOpenLogement);
                if (isSubmit) {
                    setIsValidatedLogement(true);
                }
                break;
            case "marital":
                setIsOpenSituationMaritale(!isOpenSituationMaritale);
                if (isSubmit) {
                    setIsValidatedSituationMaritale(true);
                }
                break;
            case "dettes":
                setIsOpenDebt(!isOpenDebt);
                if (isSubmit) {
                    setIsValidatedDebts(true);
                }
                break;
            case "immo":
                setIsOpenPatrimoine(!isOpenPatrimoine);
                if (isSubmit) {
                    setIsValidatedPatrimoine(true);
                }
                break;
            case "finish":
                if (isSubmit) {
                    setIsValidatedTribunal(true);
                }
        }
    };

    const validateForm = () => {
        if (
            isValidatedDocuments &&
            isValidatedInsurance &&
            isValidatedSituationMaritale &&
            isValidatedSituationPro &&
            isValidatedLogement &&
            isValidatedTribunal &&
            isValidatedDebts &&
            isValidatedPatrimoine
        ) {
            const formData = new FormData();
            for (const key in allData) {
                for (const underkey in allData[key]) {
                    if (
                        [
                            "AvisTax",
                            "AvisInsurance",
                            "bankStatements",
                            "crg",
                            "invoices",
                            "aahDoc",
                            "pensionDoc",
                            "workAccidentDoc",
                            "rsaDoc",
                            "establishmentDoc",
                            "propritaryDoc",
                            "SDFDoc",
                            "isJudgeContactedDoc",
                            "isCreancyDoc",
                            "isDebtDoc",
                            "isConsumerDebtDoc",
                        ].includes(underkey)
                    ) {
                        allData[key][underkey].map((file) => {
                            formData.append(key + "_" + underkey + "[]", file);
                        });
                    } else if (["biens", "biensMobiliers"].includes(underkey)) {
                        formData.append(
                            key + "_" + underkey,
                            JSON.stringify(allData[key][underkey])
                        );
                        allData[key][underkey].map(
                            (bien: any, index: number) => {
                                formData.append(
                                    key +
                                        "_" +
                                        underkey +
                                        `_doc_releve[${index}]`,
                                    bien["releve"][0]
                                );
                                formData.append(
                                    key +
                                        "_" +
                                        underkey +
                                        `_doc_taxe[${index}]`,
                                    bien["taxe"][0]
                                );
                            }
                        );
                    } else {
                        formData.append(
                            key + "_" + underkey,
                            allData[key][underkey]
                        );
                    }
                }
            }

            uploadInformations(addToast, formData, depot_id).then((resp) => {
                addToast("Votre dépot a été réalisé.", {
                    appearance: "success",
                });
            });

            setIsValidatedForm(true);
        } else {
            if (simplifyUpload) {
                const formData = new FormData();
                bulkUploadDoc.forEach((doc: File) => {
                    formData.append("simplify_doc" + "[]", doc);
                });

                uploadInformations(addToast, formData, depot_id).then(
                    (resp) => {
                        addToast("Votre dépot a été réalisé.", {
                            appearance: "success",
                        });
                    }
                );

                setIsValidatedForm(true);
            } else {
                addToast("Toutes les sections doivent être remplis.", {
                    appearance: "error",
                });
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        getSettingsCRG(addToast, depot_id).then((resp) => {
            setIsValidatedForm(resp.data.data.isCompleted);
            setSettings(resp.data.data);
            setNumeroRG(resp.data.data.rg_number);
            setNumeroPortalis(resp.data.data.portalis_number);
            setStartDate(formatDate(new Date(resp.data.data.start_period)));
            setEndDate(formatDate(new Date(resp.data.data.end_period)));

            if (resp.data.data.isCompleted) {
                setIsValidatedDocuments(true);
                setIsValidatedInsurance(true);
                setIsValidatedSituationPro(true);
                setIsValidatedSituationMaritale(true);
                setIsValidatedDebts(true);
                setIsValidatedPatrimoine(true);
                setIsValidatedLogement(true);
                setIsValidatedTribunal(true);
            }
            setLoading(false);
        });
    }, []);

    const isSpectator = () => {
        return !data;
    };

    const shouldSeeForm = () => {
        return (
            (!isValidatedForm && isSpectator()) ||
            ["WAITING_VERIF", "FAILED", "REVISION", "SUCCESS"].includes(
                data?.status
            )
        );
    };

    return (
        <div className="w-100">
            {!data && (
                <header
                    style={
                        settings?.color && { backgroundColor: settings.color }
                    }
                    className={
                        "w-100 h-24 bg-atroposPrimary flex justify-between"
                    }
                >
                    <div className="p-2">
                        {settings && (
                            <img
                                src={settings.logo}
                                alt="LOGO"
                                height={100}
                                width={175}
                                className="p-2 cursor-pointer"
                            />
                        )}
                    </div>
                    <div className="text-white text-sm p-2">
                        <span className="block h-12 font-semibold">
                            {settings && settings.phone}
                        </span>
                        <span className="block h-12 font-semibold">
                            {settings && settings.email}
                        </span>
                    </div>
                </header>
            )}
            {isLoading && <Loader />}
            {!isLoading &&
                !isValidatedForm &&
                !isSpectator() &&
                data.status === "WAITING_DEPOT" && (
                    <AlertMessage
                        type="warning"
                        message="En attente de dépot des documents"
                    />
                )}
            {!isLoading && (
                <div className="w-100 flex justify-center">
                    <div className="w-4/5 mt-8">
                        {shouldSeeForm() && (
                            <>
                                {!data && (
                                    <>
                                        <h2 className="text-center text-atroposPrimary">
                                            Contrôle de comptes au service des
                                            majeurs protégés
                                        </h2>
                                        <hr className="m-4" />
                                    </>
                                )}

                                <div className="flex flex-wrap -mx-3 mb-3">
                                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                                        <InputForm
                                            name="Numéro de RG"
                                            set={setNumeroRG}
                                            value={numeroRG}
                                            required={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                                        <InputForm
                                            name="Numéro Portalis"
                                            set={setNumeroPortalis}
                                            value={portalis}
                                            required={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-3">
                                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                                        <InputForm
                                            name="Début de la période"
                                            type="date"
                                            set={setStartDate}
                                            value={startDate}
                                            required={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                                        <InputForm
                                            name="Fin de la période"
                                            type="date"
                                            set={setEndDate}
                                            value={endDate}
                                            readOnly={true}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="m-6 flex align-center justify-center">
                                    <ToggleSwitch
                                        checked={simplifyUpload}
                                        onChange={() =>
                                            setSimplifyUpload(!simplifyUpload)
                                        }
                                        disabled={data}
                                        label={
                                            <div className="font-bold">
                                                Dépot de fichier simplifié
                                            </div>
                                        }
                                    />
                                </div>
                                {simplifyUpload ? (
                                    <div className="mt-6 p-6 bg-slate-200 rounded-md text-center">
                                        <DropZoneArea
                                            onDrop={(acceptedFiles: any) => {
                                                setBulkUploadDoc(
                                                    (prevFiles) => [
                                                        ...prevFiles, // Keep any previously uploaded files
                                                        ...acceptedFiles, // Spread in the new files only once
                                                    ]
                                                );
                                            }}
                                            onRemove={(fileToRemove) =>
                                                setBulkUploadDoc((prevFiles) =>
                                                    prevFiles.filter(
                                                        (file) =>
                                                            file !==
                                                            fileToRemove
                                                    )
                                                )
                                            }
                                            uploadedFiles={bulkUploadDoc}
                                            accept={["application/pdf"]}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <Accordeon
                                            name={"Situation professionnelle"}
                                            component={
                                                <SituationPro
                                                    onClose={() =>
                                                        close("pro", true)
                                                    }
                                                    setIsValidated={
                                                        setIsValidatedSituationPro
                                                    }
                                                    data={allData}
                                                    setData={setAllData}
                                                    isReadOnly={data}
                                                />
                                            }
                                            isOpen={isOpenSituationPro}
                                            setIsOpen={() => close("pro")}
                                            isFullFilled={
                                                isValidatedSituationPro
                                            }
                                        />
                                        <Accordeon
                                            name={"Situation maritale"}
                                            isOpen={isOpenSituationMaritale}
                                            setIsOpen={() => close("marital")}
                                            component={
                                                <SituationMaritale
                                                    onClose={() =>
                                                        close("marital", true)
                                                    }
                                                    setIsValidated={
                                                        setIsValidatedSituationMaritale
                                                    }
                                                    data={allData}
                                                    setData={setAllData}
                                                    isReadOnly={data}
                                                />
                                            }
                                            isFullFilled={
                                                isValidatedSituationMaritale
                                            }
                                        />
                                        <Accordeon
                                            name={"Logement"}
                                            isOpen={isOpenLogement}
                                            setIsOpen={() => close("logement")}
                                            component={
                                                <Logement
                                                    onClose={() =>
                                                        close("logement", true)
                                                    }
                                                    setIsValidated={
                                                        setIsValidatedLogement
                                                    }
                                                    isReadOnly={data}
                                                    data={allData}
                                                    setData={setAllData}
                                                />
                                            }
                                            isFullFilled={isValidatedLogement}
                                        />
                                        <Accordeon
                                            name={"Patrimoine"}
                                            component={
                                                <Patrimoine
                                                    onClose={() =>
                                                        close("immo", true)
                                                    }
                                                    setIsValidated={
                                                        setIsValidatedPatrimoine
                                                    }
                                                    data={allData}
                                                    setData={setAllData}
                                                    isReadOnly={data}
                                                />
                                            }
                                            isOpen={isOpenPatrimoine}
                                            setIsOpen={() => close("immo")}
                                            isFullFilled={isValidatedPatrimoine}
                                        />
                                        <Accordeon
                                            name={"Assurances"}
                                            component={
                                                <Insurances
                                                    onClose={() =>
                                                        close("insurance", true)
                                                    }
                                                    data={allData}
                                                    setData={setAllData}
                                                    setIsValidated={
                                                        setIsValidatedInsurance
                                                    }
                                                    isReadOnly={data}
                                                />
                                            }
                                            isOpen={isOpenInsurance}
                                            setIsOpen={() => close("insurance")}
                                            isFullFilled={isValidatedInsurance}
                                        />

                                        <Accordeon
                                            name={"Dettes et créances"}
                                            isOpen={isOpenDebt}
                                            setIsOpen={() => close("dettes")}
                                            isFullFilled={isValidatedDebts}
                                            component={
                                                <DettesEtCreances
                                                    onClose={() =>
                                                        close("dettes", true)
                                                    }
                                                    data={allData}
                                                    setData={setAllData}
                                                    setIsValidated={
                                                        setIsValidatedDebts
                                                    }
                                                    isReadOnly={data}
                                                />
                                            }
                                        />
                                        <Accordeon
                                            name={"Documents"}
                                            component={
                                                <Documents
                                                    onClose={() =>
                                                        close("documents", true)
                                                    }
                                                    setIsValidated={
                                                        setIsValidatedDocuments
                                                    }
                                                    data={allData}
                                                    setData={setAllData}
                                                    isReadOnly={data}
                                                />
                                            }
                                            isOpen={isOpenDocuments}
                                            setIsOpen={() => close("documents")}
                                            isFullFilled={isValidatedDocuments}
                                        />
                                        <Accordeon
                                            name={"Tribunal"}
                                            component={
                                                <Tribunal
                                                    onClose={() =>
                                                        close("tribunal", true)
                                                    }
                                                    setIsValidated={
                                                        setIsValidatedTribunal
                                                    }
                                                    data={allData}
                                                    setData={setAllData}
                                                    isReadOnly={data}
                                                />
                                            }
                                            isOpen={isOpenTribunal}
                                            setIsOpen={() => close("tribunal")}
                                            isFullFilled={isValidatedTribunal}
                                        />
                                    </>
                                )}

                                {!data && (
                                    <div className="mt-12 mb-12 flex justify-end">
                                        <Button
                                            type={"empty"}
                                            name={"Envoyer mes documents"}
                                            action={() => {
                                                validateForm();
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {isValidatedForm && isSpectator() && (
                            <div className="flex justify-center">
                                <div className="w-1/2 text-center rounded-md">
                                    <h3>
                                        <img
                                            src={"/imgs/checked.png"}
                                            alt="Success"
                                            className="mx-auto p-10"
                                            height={200}
                                            width={200}
                                        />
                                        Vos informations ont bien été envoyés
                                        aux vérificateurs de CRG.
                                    </h3>
                                    <hr className="m-6" />
                                    <div>
                                        <h4>
                                            Pour être tenu informer de
                                            l'évolution du contrôle :
                                        </h4>
                                        <InputForm
                                            name="Email"
                                            set={setEmail}
                                            value={email}
                                        />
                                        <div className="mt-12 mb-12 flex justify-end">
                                            <Button
                                                type={"empty"}
                                                name={
                                                    "Recevoir les avancées du contrôle"
                                                }
                                                action={() => {
                                                    addEmailToMandat(
                                                        addToast,
                                                        depot_id,
                                                        { email }
                                                    ).then((resp) => {
                                                        addToast(resp.data, {
                                                            appearance: "info",
                                                        });
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
