import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { changeStatusMandat, getControls } from "../../../../_api/Mandat";
import { AlertMessage } from "../../../../_shared/AlertMessage";
import { Button } from "../../../../_shared/Button";
import InputSwitch from "../../../layout/forms/InputSwitch";
import Loader from "../../../layout/Loader";
import { useAuth } from "../../Login/auth.context";

const StatusControle = {
    SUCCESS: "Contrôle Valide",
    PENDING: "En cours",
    WAITING_DEPOT: "En attente de dépot",
    WAITING_VERIF: "En attente de votre controle",
    FAILED: "Contrôle Refusé",
    REVISION: "En attente de justificatif",
    PENDING_MJPM_VALIDATION: "En attente de validation contrôleur",
};

export const ListMandats = () => {
    const { addToast } = useToasts();
    const { setCurrentUser, currentUser } = useAuth();

    const history = useHistory();
    const [controls, setControls] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredControls, setFilteredControls] = useState([]);
    const [archivedProtege, setisArchived] = useState(false);

    const loadControls = () => {
        getControls(addToast).then((resp) => {
            setControls(resp.data);
            const filteredData = resp.data.filter(
                (item) => item.isArchived === archivedProtege
            );
            setFilteredControls(filteredData);
            setIsLoading(false);
        });
    };
    useEffect(() => {
        setIsLoading(true);

        loadControls();
    }, []);

    const [selectedCategory, setSelectedCategory] = useState("");
    const filteredData = selectedCategory
        ? filteredControls.filter((item) => item.status === selectedCategory)
        : filteredControls;

    const [currentPage, setCurrentPage] = useState(1); // Page actuelle
    const [itemsPerPage] = useState(12); // Nombre de lignes par page

    // Calculer le nombre total de pages
    const totalPages = Math.ceil(filteredControls.length / itemsPerPage);

    // Déterminer les éléments à afficher pour la page actuelle
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1); // Reset page to 1 when filtering
    };

    // Récupérer les catégories uniques pour le filtre
    const uniqueCategories = [
        ...new Set(filteredControls.map((item) => item.status)),
    ];

    return (
        <>
            <div>
                <div className="flex justify-end m-2">
                    <InputSwitch
                        id="isArchivedProtege"
                        className="column-item-flex-1"
                        onChange={() => {
                            const filteredData = controls.filter(
                                (item) => item.isArchived === !archivedProtege
                            );
                            setisArchived(!archivedProtege);
                            setFilteredControls(filteredData);
                        }}
                        label="Mandats"
                        isSwitchMajeur={true}
                        onText="Archivés"
                        offText="Courants"
                        value={archivedProtege}
                    />
                    <Button
                        type={"empty"}
                        name={"Ajouter un mandat"}
                        action={() => {
                            history.push(`/create_mandat`);
                        }}
                    />
                </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    {isLoading && (
                        <div className="flex items-center justify-center h-64">
                            <Loader />
                        </div>
                    )}
                    {!isLoading &&
                        currentItems.map((item, index) => {
                            if (
                                item.status === "PENDING_MJPM_VALIDATION" &&
                                currentUser.roles[0] !== "judge"
                            ) {
                                let name = item.name
                                    ? item.second_name + " " + item.name
                                    : item.second_name;
                                return (
                                    <AlertMessage
                                        type="warning"
                                        message={
                                            <>
                                                Nouveau mandat à prendre en
                                                charge - {name} :{" "}
                                                <a
                                                    className="underline"
                                                    onClick={() => {
                                                        const payload = {
                                                            status: "PENDING",
                                                        };
                                                        changeStatusMandat(
                                                            addToast,
                                                            item._id,
                                                            payload
                                                        ).then((resp) => {
                                                            addToast(
                                                                resp.data
                                                                    .message,
                                                                {
                                                                    appearance:
                                                                        "success",
                                                                }
                                                            );
                                                            loadControls();
                                                            console.log(
                                                                resp.data
                                                            );
                                                        });
                                                    }}
                                                >
                                                    Valider la prise en charge
                                                </a>
                                            </>
                                        }
                                    />
                                );
                            }
                        })}
                    {!isLoading && (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    {currentUser.roles[0] === "judge" && (
                                        <th scope="col" className="px-6 py-3">
                                            Contrôleur
                                        </th>
                                    )}
                                    <th scope="col" className="px-6 py-3">
                                        Numéro de RG
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Tribunal
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Portalis
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Majeur
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Représentant
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, index) => {
                                    if (
                                        currentUser.roles[0] === "judge" ||
                                        (currentUser.roles[0] !== "judge" &&
                                            item.status !==
                                                "PENDING_MJPM_VALIDATION")
                                    ) {
                                        return (
                                            <tr
                                                key={index}
                                                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                            >
                                                {currentUser.roles[0] ===
                                                    "judge" && (
                                                    <td className="px-6 py-4">
                                                        {item.controler_name}
                                                    </td>
                                                )}
                                                <td className="px-6 py-4">
                                                    {item.rg_number}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.tribunal_name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.portalis_number}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.name
                                                        ? item.second_name +
                                                          " " +
                                                          item.name
                                                        : item.second_name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.tutor_name ||
                                                        "Non renseigné"}
                                                </td>

                                                <td className="px-6 py-4">
                                                    <Link
                                                        to={
                                                            "/mandat/" +
                                                            item._id
                                                        }
                                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2"
                                                    >
                                                        Voir
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                                {currentItems.length === 0 && (
                                    <tr className=" text-center odd:bg-white font-bold border-b dark:border-gray-700 h-12">
                                        <td colSpan={6}>
                                            Il n'y a actuellement aucun controle
                                            de réaliser.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            className={`px-4 py-2 mx-1 text-sm font-medium text-white ${
                                currentPage === index + 1
                                    ? "bg-blue-500"
                                    : "bg-gray-700"
                            } rounded-md hover:bg-blue-700`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};
