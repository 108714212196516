import React from "react";
import { useDropzone } from "react-dropzone";
import { base64ToFile } from "../../../../utils/tools";

const DropZoneArea: React.FC<{
    onDrop: any;
    accept?: any;
    open?: any;
    uploadedFiles?: File[];
    isReadOnly: boolean;
    onRemove?: (file: File) => void;
}> = (props) => {
    const { onDrop, accept, open, uploadedFiles, onRemove, isReadOnly } = props;
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            accept,
            onDrop,
            disabled: isReadOnly, // Désactive le dropzone si isReadOnly est true
        });

    const downloadFile = (base64string: string) => {
        const files = base64ToFile([base64string], "doc");
        files.map((file) => {
            const url = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = url;
            link.download = "file.name"; // Nom du fichier
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url); // Nettoyage de l'URL temporaire
        });
    };
    return (
        <div>
            <div {...getRootProps({ className: "dropzone" })}>
                <input className="input-zone" {...getInputProps()} />
                {!isReadOnly && (
                    <div className="text-center">
                        <p className="dropzone-content">
                            Déposer les fichiers ici
                        </p>
                    </div>
                )}
                {uploadedFiles.length > 0 && (
                    <div className="mt-4">
                        <h4>Fichiers :</h4>
                        <ul>
                            {uploadedFiles.map((file, index) => {
                                return (
                                    <li key={index} className="file-item">
                                        <span className="file-item flex items-center justify-between w-full max-w-xs overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                                            {file.name} -
                                            {(file.size / 1024).toFixed(2)} Ko
                                        </span>

                                        {!isReadOnly && onRemove && (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onRemove(file);
                                                }}
                                                className="ml-2 text-red-600"
                                            >
                                                Retirer
                                            </button>
                                        )}
                                        {isReadOnly && (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    downloadFile(file);
                                                }}
                                                className="ml-2 text-red-600"
                                            >
                                                Télécharger
                                            </button>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DropZoneArea;
