import React, { useEffect, useState } from "react";
import AutoSuggest from "react-autosuggest";
import { useHistory, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import {
    capitalizeFirstLetterOnly,
    stringToFloat,
} from "../../../constants/Utils";
import IAccount from "../../../interfaces/IAccount";
import IBank from "../../../interfaces/IBank";
import IProtectedAdult from "../../../interfaces/IProtectedAdult";
import { request } from "../../../utils/axios-utils";
import FullBarTitle from "../../layout/FullBarTitle";
import SubBar from "../../layout/SubBar";
import FormAddLabelWithSubCategoriesAndAmount from "../../layout/forms/FormAddLabelWithSubCategoriesAndAmount";
import FormButton from "../../layout/forms/FormButton";
import InputComponent from "../../layout/forms/InputComponent";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import InputSwitch from "../../layout/forms/InputSwitch";
import ListLinkLabelsWithCategories from "../../layout/forms/ListLinkLabelsWithCategories";
import LockAccount from "../../layout/forms/LockAccount";
import Select from "../../layout/forms/Select";
import Textarea from "../../layout/forms/Textarea";

const CreateUpdate: React.FC = () => {
    let searchBySelect = false;

    const [
        editingLinkedLabelWithCategory,
        setEditingLinkedLabelWithCategoryValue,
    ] = useState<any>(null);

    const [notes, setNotes] = useState<string>("");
    const [account, setAccount] = useState<IAccount>(null);
    const [majeur, setMajeur] = useState<IProtectedAdult>(null);
    const [bank, setBank] = useState<IBank>(null);
    const [labelBanks, setLabelBanks] = useState<any>(null);
    const [additional_information, setAdditional_information] =
        useState<string>("");
    const [
        suggestionsAutocompletionForBank,
        setSuggestionsAutocompletionForBank,
    ] = useState<any>([]);

    const [selectedAccount, setSelectedAccount] = useState<any>(null);
    const [selectedMvt, setSelectedMvt] = useState<any>(null);

    const [accountsOptions, setAccountsOptions] = useState<any>(null);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const [listSubCategoriesForSelect, setListSubCategoriesForSelect] =
        useState<Array<any>>(null);
    const [listOfLinkedLabels, setListOfLinkedLabels] =
        useState<Array<any>>(null);
    const [selectedCategory, setSelectedCategory] = useState<number>(0);
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>(null);

    const [vu, setVu] = useState<boolean>(null);
    const [lifeMoneyMonthly, setLifeMoneyMonthly] = useState<boolean>(true);
    const [lifeMoneyAmount, setLifeMoneyAmount] = useState<number>(0);
    const [principal, setPrincipal] = useState<boolean>(false);
    const [closed, setClosed] = useState<boolean>(false);
    const [locks, setLocks] = useState<Array<boolean>>(null);

    const [bic, setBic] = useState<string>("");
    const [iban, setIban] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [startBalance, setStartBalance] = useState<string>("0");
    const [catergoryAccountSelected, setCatergoryAccountSelected] =
        useState<string>(account ? account.category : "Compte courant");
    const [bankAutocompletionValue, setBankAutocompletionValue] =
        useState<string>("");

    const [bankId, setBankId] = useState<string>("");
    const [bankLabel, setBankLabel] = useState<string>("");

    // Dates.
    const [startBalanceDate, setStartBalanceDate] = useState<Date>();
    const [dateClosed, setDateClosed] = useState<Date>();

    const { majeurId }: { majeurId?: string } = useParams();
    const { accountId }: { accountId?: string } = useParams();
    const { addToast } = useToasts();
    const history = useHistory();

    interface Language {
        name: string;
        year: number;
    }

    const Autosuggest = AutoSuggest as { new (): AutoSuggest<Language> };

    useEffect(() => {
        // Get all banks labels
        if (!labelBanks) {
            request({ addToast, url: "/api/banks/labelBanks" })
                .then(({ data }) => {
                    setLabelBanks(data);
                })
                .catch();
        }

        //Get locks accounts
        if (!locks) {
            actualiseLocks();
        }

        // If it is an account modification
        if (accountId && !account) {
            request({ addToast, url: `/api/accounts/${accountId}` })
                .then(({ data }) => {
                    setMajeur(data.majeur);
                    setAccount(data.account);
                    setBank(data.bank);
                    setBankAutocompletionValue(
                        `${data.bank.name} ${data.bank.agency}`
                    );
                    setClosed(data.account.closed);
                    setPrincipal(data.account.principal);
                    setVu((data.account.vu && data.account.vu) || false);
                    setCatergoryAccountSelected(data.account.category);
                    setStartBalanceDate(
                        new Date(
                            data.account.date_start_balance.$date
                                ? parseInt(
                                      data.account.date_start_balance.$date
                                          .$numberLong
                                  )
                                : data.account.date_start_balance
                        )
                    );
                    setStartBalance(data.account.start_balance.toString());
                    setBankLabel(data.account.bank_label);
                    setBankId(data.account.bank_id);
                    setNumber(data.account.number);
                    setClosed(data.account.closed);
                    setType(data.account.type);
                    setIban((data.account.iban && data.account.iban) || null);
                    setBic((data.account.bic && data.account.bic) || null);
                    setNotes(data.account.notes);
                    setAdditional_information(
                        data.account.additional_information
                    );
                    setLifeMoneyAmount(data.account.life_money_amount || 0);
                    setLifeMoneyMonthly(
                        (data.account.life_money_period &&
                            data.account.life_money_period === "month") ||
                            true
                    );

                    if (data.account.date_closed) {
                        setDateClosed(
                            new Date(
                                data.account.date_closed.$date
                                    ? parseInt(
                                          data.account.date_closed.$date
                                              .$numberLong
                                      )
                                    : data.account.date_closed
                            )
                        );
                    } else {
                        setDateClosed(null);
                    }

                    // load categories / labels / subcategries
                    if (
                        !listSubCategoriesForSelect &&
                        !listOfLinkedLabels &&
                        !accountsOptions
                    ) {
                        updateSubCategoriesForSelect(selectedCategory);
                        updateAccountsOptions(accountId);
                    }
                })
                .catch();
        }

        if (listSubCategoriesForSelect != null && isFirstLoad) {
            updateLinkedLabels(
                0,
                listSubCategoriesForSelect[0].label,
                accountId,
                null,
                null
            );
            setIsFirstLoad(false);
        }

        // If it is a new Account, it loads only the PA.
        if (majeurId && !majeur) {
            request({ addToast, url: `/api/majeur/${majeurId}` })
                .then(({ data }) => {
                    setMajeur(data);
                })
                .catch();
        }
    }, [
        account,
        locks,
        labelBanks,
        listSubCategoriesForSelect,
        listOfLinkedLabels,
    ]);

    useEffect(() => {
        setType("");
    }, [catergoryAccountSelected]);

    /**
     * Save an account (it can be a saving as a modification)
     */
    const saveAccount = () => {
        request({
            addToast,
            url: "/api/accounts/create",
            method: "post",
            data: {
                account: {
                    majeur_id: majeurId,
                    bank_id: bankId,
                    bank_label: bankLabel,
                    category: catergoryAccountSelected,
                    type: type,
                    iban: iban,
                    bic: bic,
                    start_balance: startBalance,
                    date_start_balance: startBalanceDate,
                    number: number,
                    additional_information: additional_information,
                    date_closed: dateClosed,
                    notes: notes || "",
                    life_money_period: lifeMoneyMonthly ? "month" : "week",
                    lifeMoneyAmount: lifeMoneyAmount,
                },
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast("Le compte a bien été créé", {
                    appearance: "success",
                });
                history.push(`/single_majeur/${majeurId}/comptes`);
            })
            .catch();
    };

    const showEditLinkedLabelWithCategory = (linkedLabelWithCategory: any) => {
        setEditingLinkedLabelWithCategoryValue(linkedLabelWithCategory);
    };

    /**
     * Save an account (it can be a saving as a modification)
     */
    const updateAccount = () => {
        if (closed && !dateClosed) {
            addToast("Il faut une date de clôture", { appearance: "error" });
        } else {
            request({
                addToast,
                url: "/api/accounts/update",
                method: "post",
                data: {
                    account: {
                        date_start_balance: startBalanceDate,
                        category: catergoryAccountSelected,
                        start_balance: startBalance,
                        date_closed: dateClosed,
                        bank_label: bankLabel,
                        account_id: accountId,
                        principal: principal,
                        majeur_id: account.majeur_id,
                        bank_id: bankId,
                        number: number,
                        additional_information: additional_information,
                        closed: closed,
                        type: type,
                        iban: iban,
                        bic: bic,
                        vu: vu,
                        notes: notes || "",
                        life_money_period: lifeMoneyMonthly ? "month" : "week",
                        life_money_amount: lifeMoneyAmount,
                    },
                },
            })
                .then((data) => {
                    // The data has been saved.
                    addToast("Le compte a bien été modifié", {
                        appearance: "success",
                    });
                    history.push(`/single_account/${accountId}`);
                })
                .catch();
        }
    };

    /*
     Autosuggest for the bank
    */
    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestionsBankAutocompletion = (value: any, labelBanks: any) => {
        const escapedValue = value
            ? value.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            : "";
        if (escapedValue === "") return [];
        const regex = new RegExp(`^${escapedValue}`, "i");

        if (labelBanks) {
            return Object.keys(labelBanks)
                .map((groupName: any) => ({
                    name: labelBanks[groupName].name,
                    banks: labelBanks[groupName].banks.filter((bank: IBank) =>
                        regex.test(`${bank.name} ${bank.agency}`)
                    ),
                }))
                .filter(
                    (filteringGroup: any) => filteringGroup.banks.length > 0
                );
        }

        return [];
    };

    // How to render the title of Section for suggestion
    const renderSectionTitle = (section: any) => {
        return <h5 className="section_autosuggest_banks">{section.name}</h5>;
    };

    // How to render the title of Section for suggestion
    const getSectionSuggestions = (section: any) => {
        return section.banks;
    };

    // When suggestion is clicked, Autosuggest needs to populate the input field
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValueBankAutocompletion = (section: any) => {
        setBankId(section._id);
        setBankLabel(`${section.name} ${section.agency}`);
        return `${section.name} ${section.agency}`;
    };

    // Use your imagination to render suggestions.
    const renderSuggestionBankAutocompletion = (section: any) => {
        return <span>{section.agency}</span>;
    };

    // Update input for bank in the state
    const onChangeBankAutocompletionValue = (newValue: any) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        setBankAutocompletionValue(newValue);

        if (!newValue) {
            setBankId("");
            setBankLabel("");
        }
    };

    const onBlurBank = () => {
        if (!bankId || !bankLabel || bankLabel !== bankAutocompletionValue) {
            addToast("Attention cette banque est inconnue", {
                appearance: "warning",
            });
            setBankId("");
        }
    };

    // Update list of locked accounts
    const actualiseLocks = () => {
        let url =
            (accountId && `/api/accounts/locks/${accountId}`) ||
            `/api/accounts/locks`;
        request({ addToast, url: url })
            .then(({ data }) => {
                setLocks(data);
            })
            .catch();
    };

    // Linked labels functions
    const removeLinkedLabelWithCategoryAndAmount = (
        label: any,
        amount: number
    ) => {
        request({
            addToast,
            url: `/api/users/linkedLabelsWithAmount/destroy/${accountId}`,
            method: "post",
            data: { label: label },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label a bien été supprimé`, {
                    appearance: "success",
                });
                updateLinkedLabels(
                    selectedCategory,
                    selectedSubCategory.label,
                    accountId,
                    selectedAccount,
                    selectedMvt
                );
            })
            .catch();
    };

    const updateLinkedLabelWithCategoryAndAmount = (
        newLinkLabelsWithCategory: string,
        amount: number,
        label: any
    ) => {
        request({
            addToast,
            url: `/api/users/linkedLabelsWithAmount/update/${accountId}`,
            method: "post",
            data: {
                label: label,
                amount: Number(amount),
                newLinkLabelsWithCategory: newLinkLabelsWithCategory,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label a bien été modifié`, {
                    appearance: "success",
                });
                updateLinkedLabels(
                    selectedCategory,
                    selectedSubCategory.label,
                    accountId,
                    selectedAccount,
                    selectedMvt
                );
                setEditingLinkedLabelWithCategoryValue(null);
            })
            .catch();
    };

    const createLinkedLabel = (
        mvt: number,
        category: number,
        subCategory: string,
        label: string,
        selectedAccount: any,
        amount: number
    ) => {
        const newLabel = capitalizeFirstLetterOnly(label);
        request({
            addToast,
            url: `/api/users/linkedLabelsWithAmount/store`,
            method: "post",
            data: {
                account_id: accountId,
                mvt: Number(mvt),
                category: Number(category),
                sub_category: subCategory,
                label: newLabel,
                amount: Number(amount),
                selected_account: selectedAccount,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label a bien été associé`, {
                    appearance: "success",
                });
                updateLinkedLabels(
                    category,
                    selectedSubCategory.label,
                    accountId,
                    selectedAccount,
                    mvt
                );
            })
            .catch();
    };

    const getSelectedLinkedSubCat = (
        c: string,
        label?: string,
        account?: any,
        mvt?: number
    ) => {
        if (label != null) {
            updateLinkedLabels(parseInt(c), label, accountId);
        } else if (account) {
            updateLinkedLabels(parseInt(c), null, accountId, account, mvt);
        } else {
            searchBySelect = true;
            updateSubCategoriesForSelect(parseInt(c));
        }
    };

    const updateSubCategoriesForSelect = (c: number) => {
        request({ addToast, url: `/api/users/subCategories/${c}` })
            .then(({ data }) => {
                let subCategoriesForSelect: Array<any> = Array();
                data.forEach(function (subCat: string, index: number) {
                    subCategoriesForSelect.push({
                        label: subCat,
                        value: String(index),
                        key: index,
                    });
                });
                setListSubCategoriesForSelect(subCategoriesForSelect);
                setSelectedSubCategory(subCategoriesForSelect[0]);
                if (searchBySelect == true) {
                    setSelectedSubCategory(subCategoriesForSelect[0]);
                    updateLinkedLabels(
                        c,
                        subCategoriesForSelect[0].label,
                        accountId
                    );
                    searchBySelect = false;
                }
            })
            .catch();
    };

    const updateLinkedLabels = (
        category: number,
        subCategory: string,
        accountId: string,
        selectedAccount?: any,
        mouvement?: number
    ) => {
        if (selectedAccount != null) {
            request({
                addToast,
                url: `/api/users/linkedLabelsWithAmount`,
                method: "post",
                data: {
                    account_id: accountId,
                    mvt: Number(mouvement),
                    account: selectedAccount,
                },
            })
                .then(({ data }) => {
                    if (typeof data.labels === "object") {
                        let array = Object.keys(data.labels).map(
                            (k) => data.labels[k]
                        );
                        setListOfLinkedLabels(array);
                    } else {
                        setListOfLinkedLabels(data.labels);
                    }
                })
                .catch();
        } else {
            subCategory == "?" ? (subCategory = "%3F") : "";
            request({
                addToast,
                url: `/api/users/linkedLabelsWithAmount/${category}/${subCategory}/${accountId}`,
            })
                .then(({ data }) => {
                    if (typeof data.labels === "object") {
                        let array = Object.keys(data.labels).map(
                            (k) => data.labels[k]
                        );
                        setListOfLinkedLabels(array);
                    } else {
                        setListOfLinkedLabels(data.labels);
                    }
                })
                .catch();
        }
    };

    // Update the accounts Options for the Select
    const updateAccountsOptions = (accountId: string) => {
        request({
            addToast,
            url: `/api/users/linkedLabelsWithAmount/accountsOptions/${accountId}`,
        })
            .then(({ data }) => {
                setAccountsOptions(data.accountsOptions);
            })
            .catch();
    };

    const updateInfos = (account: any, mvt: number) => {
        setSelectedAccount(account);
        setSelectedMvt(mvt);
    };

    return (
        <>
            {!accountId && !account && majeur && (
                <SubBar
                    title={`${majeur.first_name}  ${majeur.last_name} | Ajouter un compte`}
                    back={`/single_majeur/${majeurId}`}
                />
            )}

            {accountId && account && majeur && (
                <SubBar
                    title={`${majeur.first_name}  ${majeur.last_name} | Modifier le compte`}
                    back={`/single_account/${accountId}`}
                />
            )}

            <div className="Accounts form_account">
                {/* Arrowback was here */}
                <div>
                    {labelBanks && labelBanks.length === 0 && (
                        <div className="layout-center-center row div-no-items">
                            Vous devez d&#039;abord ajouter une banque.
                        </div>
                    )}
                    {labelBanks && Object.keys(labelBanks).length > 0 && (
                        <div>
                            <form className="column page_content">
                                <div className="my-input-container column">
                                    <label>
                                        Banque :{" "}
                                        <span className="red_asterix">*</span>
                                    </label>
                                    <Autosuggest
                                        multiSection
                                        renderSectionTitle={(section: any) =>
                                            renderSectionTitle(section)
                                        }
                                        getSectionSuggestions={(section: any) =>
                                            getSectionSuggestions(section)
                                        }
                                        suggestions={
                                            suggestionsAutocompletionForBank
                                        }
                                        onSuggestionsFetchRequested={(
                                            section: any
                                        ) =>
                                            setSuggestionsAutocompletionForBank(
                                                getSuggestionsBankAutocompletion(
                                                    section.value,
                                                    labelBanks
                                                )
                                            )
                                        }
                                        onSuggestionsClearRequested={() =>
                                            setSuggestionsAutocompletionForBank(
                                                []
                                            )
                                        }
                                        getSuggestionValue={(bank: any) =>
                                            getSuggestionValueBankAutocompletion(
                                                bank
                                            )
                                        }
                                        renderSuggestion={(bank: any) =>
                                            renderSuggestionBankAutocompletion(
                                                bank
                                            )
                                        }
                                        inputProps={{
                                            placeholder:
                                                "Rechercher une banque...",
                                            value: bankAutocompletionValue,
                                            onChange: (
                                                event: any,
                                                section: any
                                            ) => {
                                                onChangeBankAutocompletionValue(
                                                    section.newValue
                                                );
                                            },
                                            onBlur: () => onBlurBank(),
                                        }}
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <Select
                                        id="category"
                                        value={catergoryAccountSelected}
                                        className="flex-1"
                                        label="Catégorie du compte"
                                        required
                                        onChange={(e) =>
                                            setCatergoryAccountSelected(
                                                e.target.value
                                            )
                                        }
                                        options={[
                                            {
                                                label: "Compte courant",
                                                value: "Compte courant",
                                            },
                                            {
                                                label: "Compte épargne",
                                                value: "Compte épargne",
                                            },
                                            {
                                                label: "Assurance vie",
                                                value: "Assurance vie",
                                            },
                                            {
                                                label: "Contrat capitalisation",
                                                value: "Contrat capitalisation",
                                            },
                                            {
                                                label: "Valeurs mobilières, Titres",
                                                value: "Valeurs mobilières, Titres",
                                            },
                                            { label: "SCPI", value: "SCPI" },
                                        ]}
                                    />
                                    <div className="flex005 hide-xs" />

                                    {catergoryAccountSelected !==
                                        "Compte épargne" && (
                                        <InputComponent
                                            id="type"
                                            type="text"
                                            className="flex-1"
                                            label="Type"
                                            required
                                            value={type}
                                            onChange={(e) =>
                                                setType(e.target.value)
                                            }
                                            maxLength={50}
                                        />
                                    )}

                                    {catergoryAccountSelected ===
                                        "Compte épargne" && (
                                        <Select
                                            id="select-type"
                                            value={type}
                                            className="flex-1"
                                            label="Type"
                                            required
                                            onChange={(e) =>
                                                setType(e.target.value)
                                            }
                                            options={[
                                                {
                                                    label: "Capital extension",
                                                    value: "Capital extension",
                                                },
                                                {
                                                    label: "CEL",
                                                    value: "CEL",
                                                },
                                                {
                                                    label: "CODEBIS",
                                                    value: "CODEBIS",
                                                },
                                                {
                                                    label: "Co développement",
                                                    value: "Co développement",
                                                },
                                                {
                                                    label: "Comptes à terme",
                                                    value: "Comptes à terme",
                                                },
                                                {
                                                    label: "CSL",
                                                    value: "CSL",
                                                },
                                                {
                                                    label: "Epargne logement",
                                                    value: "Epargne logement",
                                                },
                                                {
                                                    label: "Epargne salariale",
                                                    value: "Epargne salariale",
                                                },
                                                {
                                                    label: "LEP",
                                                    value: "LEP",
                                                },
                                                {
                                                    label: "LES",
                                                    value: "LES",
                                                },
                                                {
                                                    label: "LDD",
                                                    value: "LDD",
                                                },
                                                {
                                                    label: "Livret A",
                                                    value: "Livret A",
                                                },
                                                {
                                                    label: "Livret bleu",
                                                    value: "Livret bleu",
                                                },
                                                {
                                                    label: "Livret jeune",
                                                    value: "Livret jeune",
                                                },
                                                {
                                                    label: "PEL",
                                                    value: "PEL",
                                                },
                                                {
                                                    label: "PEP",
                                                    value: "PEP",
                                                },
                                                {
                                                    label: "PERP",
                                                    value: "PERP",
                                                },
                                            ]}
                                        />
                                    )}

                                    <div className="flex005 hide-xs" />

                                    <InputComponent
                                        id="complement"
                                        type="text"
                                        className="flex-1"
                                        label="Complément d'appélation"
                                        value={additional_information}
                                        onChange={(e) =>
                                            setAdditional_information(
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <InputComponent
                                        id="type"
                                        type="text"
                                        className="flex-1"
                                        label="Numéro du compte (11 caractères)"
                                        required
                                        value={number}
                                        onChange={(e) =>
                                            setNumber(e.target.value)
                                        }
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="type"
                                        type="text"
                                        className="flex-1"
                                        label="Iban"
                                        value={iban}
                                        onChange={(e) =>
                                            setIban(e.target.value)
                                        }
                                        maxLength={34}
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="bic"
                                        type="text"
                                        className="flex-1"
                                        label="BIC"
                                        value={bic}
                                        onChange={(e) => setBic(e.target.value)}
                                        maxLength={11}
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <InputDatePicker
                                        id="start_date"
                                        inputDate={startBalanceDate}
                                        label="Date de prise en charge du compte"
                                        required
                                        onChange={(date: Date) => {
                                            setStartBalanceDate(date);
                                        }}
                                    />
                                    <div className="flex005 hide-xs" />
                                    <InputComponent
                                        id="solde"
                                        type="text"
                                        className="flex-1"
                                        label="Solde de départ"
                                        needBlur={true}
                                        onBlur={() => {
                                            setStartBalance(
                                                String(
                                                    parseFloat(startBalance)
                                                ) || "0"
                                            );
                                        }}
                                        required
                                        value={startBalance}
                                        onChange={(e) =>
                                            setStartBalance(
                                                stringToFloat(e.target.value)
                                            )
                                        }
                                        maxLength={50}
                                    />
                                </div>
                                {accountId && account && (
                                    <div>
                                        <div className="row layout-xs-column">
                                            <div
                                                className="row layout-start-center"
                                                style={{ marginTop: 6 }}
                                            >
                                                <InputSwitch
                                                    id="isClosed"
                                                    className="column-item-flex-1"
                                                    label="Clôturé"
                                                    isSwitchMajeur={true}
                                                    value={closed}
                                                    onChange={() => {
                                                        if (!closed == false)
                                                            setDateClosed(null);
                                                        setClosed(!closed);
                                                    }}
                                                />
                                            </div>
                                            <div className="hide-xs flex005" />
                                            {closed && (
                                                <InputDatePicker
                                                    id="closed_date"
                                                    inputDate={dateClosed}
                                                    label="Date de clôture"
                                                    required
                                                    onChange={(date: Date) => {
                                                        if (date) {
                                                            setDateClosed(date);
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {account.is_synced && (
                                            <div
                                                style={{
                                                    marginTop: 15,
                                                    marginBottom: 15,
                                                }}
                                            />
                                        )}
                                        <div className="column">
                                            <div style={{ marginBottom: 15 }} />
                                            <InputSwitch
                                                id="principal"
                                                className="column-item-flex-1"
                                                label="Principal"
                                                value={principal}
                                                onChange={() =>
                                                    setPrincipal(!principal)
                                                }
                                            />
                                            <div style={{ marginBottom: 15 }} />
                                            <InputSwitch
                                                id="vu"
                                                className="column-item-flex-1"
                                                label="Calculer seulement les opérations verifiées"
                                                value={vu}
                                                onChange={() => setVu(!vu)}
                                            />
                                            <div style={{ marginBottom: 15 }} />
                                            <div
                                                className={
                                                    "row layout-center-center"
                                                }
                                            >
                                                <InputComponent
                                                    id={"lifeMoneyAmount"}
                                                    type={"number"}
                                                    className={"flex-1"}
                                                    label={
                                                        "Montant de l'argent de vie"
                                                    }
                                                    value={lifeMoneyAmount}
                                                    onChange={(e) =>
                                                        setLifeMoneyAmount(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <InputSwitch
                                                    id="lifeMoneyDuration"
                                                    className="column-item-flex-1"
                                                    style={{
                                                        marginLeft: "15px",
                                                    }}
                                                    label="Période"
                                                    onText={"Mois"}
                                                    offText={"Semaine"}
                                                    value={lifeMoneyMonthly}
                                                    onChange={() =>
                                                        setLifeMoneyMonthly(
                                                            !lifeMoneyMonthly
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="margin-top">
                                            <Textarea
                                                id="notes"
                                                label="Notes"
                                                value={notes}
                                                onChange={(e) =>
                                                    setNotes(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                <FormButton
                                    onClick={() => {
                                        if (startBalance) {
                                            if (
                                                typeof startBalance === "string"
                                            ) {
                                                accountId
                                                    ? updateAccount()
                                                    : saveAccount();
                                            }
                                        } else {
                                            addToast(
                                                "Attention au solde de départ",
                                                { appearance: "warning" }
                                            );
                                        }
                                    }}
                                    style={{ marginTop: 20 }}
                                    text={!accountId ? "Ajouter" : "Modifier"}
                                />
                            </form>

                            {(accountId && (
                                <LockAccount
                                    locks={locks}
                                    actualiseLocks={() => actualiseLocks()}
                                    accountId={accountId}
                                />
                            )) || (
                                <LockAccount
                                    locks={locks}
                                    actualiseLocks={() => actualiseLocks()}
                                />
                            )}

                            {accountId &&
                                listSubCategoriesForSelect &&
                                selectedSubCategory &&
                                listOfLinkedLabels && (
                                    <div style={{ marginTop: 15 }}>
                                        <div className="tele_trans_link_label">
                                            <FullBarTitle text="Relier des labels aux libellés des opérations bancaires" />

                                            <div
                                                className="row page_content"
                                                style={{ marginTop: 10 }}
                                            >
                                                <FormAddLabelWithSubCategoriesAndAmount
                                                    accountsOptions={
                                                        accountsOptions
                                                    }
                                                    setSelectedCategory={
                                                        setSelectedCategory
                                                    }
                                                    setSelectedSubCategory={
                                                        setSelectedSubCategory
                                                    }
                                                    getSubCategoriesFormLinkedLabel={(
                                                        c: string,
                                                        label?: string,
                                                        account?: any,
                                                        mvt?: number
                                                    ) =>
                                                        getSelectedLinkedSubCat(
                                                            c,
                                                            label,
                                                            account,
                                                            mvt
                                                        )
                                                    }
                                                    listSubCategoriesForSelect={
                                                        listSubCategoriesForSelect
                                                    }
                                                    submit={(
                                                        m: number,
                                                        c: number,
                                                        s: string,
                                                        l: string,
                                                        a: any,
                                                        amount: number
                                                    ) =>
                                                        createLinkedLabel(
                                                            m,
                                                            c,
                                                            s,
                                                            l,
                                                            a,
                                                            amount
                                                        )
                                                    }
                                                    selectedSubCategory={
                                                        selectedSubCategory
                                                    }
                                                    updateInfos={(
                                                        account: any,
                                                        mvt: number
                                                    ) =>
                                                        updateInfos(
                                                            account,
                                                            mvt
                                                        )
                                                    }
                                                />

                                                <ListLinkLabelsWithCategories
                                                    withAmount={true}
                                                    list={listOfLinkedLabels}
                                                    remove={(
                                                        label: any,
                                                        amount: number
                                                    ) =>
                                                        removeLinkedLabelWithCategoryAndAmount(
                                                            label,
                                                            amount
                                                        )
                                                    }
                                                    editLinkedLabelWithCategory={(
                                                        l: any
                                                    ) =>
                                                        showEditLinkedLabelWithCategory(
                                                            l
                                                        )
                                                    }
                                                    updateLinkedLabelWithCategory={(
                                                        newLinkLabelsWithCategory: string,
                                                        amount: number,
                                                        label: any
                                                    ) =>
                                                        updateLinkedLabelWithCategoryAndAmount(
                                                            newLinkLabelsWithCategory,
                                                            amount,
                                                            label
                                                        )
                                                    }
                                                    linkedLabelWithCategoryInEdit={
                                                        editingLinkedLabelWithCategory
                                                    }
                                                    cancel={() =>
                                                        setEditingLinkedLabelWithCategoryValue(
                                                            null
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CreateUpdate;
