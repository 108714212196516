import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Button } from "../../../../../_shared/Button";
import { ToggleSwitch } from "../../../../../_shared/ToggleSwitch";
import { DocUpload } from "./DocUpload";

export const DettesEtCreances = (props: {
    onClose: Function;
    setIsValidated: Function;
    isReadOnly: boolean;
    setData: Function;
    data: any;
}) => {
    const { onClose, setIsValidated, isReadOnly, data, setData } = props;
    const { addToast } = useToasts();

    const [isDebt, setIsDebt] = useState((data && data.debts.isDebt) || false);
    const [isDebtDoc, setIsDebtDoc] = useState(
        (data && data.debts.isDebtDoc) || null
    );

    const [isConsumerDebt, setIsConsumerDebt] = useState(
        (data && data.debts.isConsumerDebt) || false
    );
    const [isConsumerDebtDoc, setIsConsumerDebtDoc] = useState(
        (data && data.debts.isConsumerDebtDoc) || null
    );

    const [isCreancy, setIsCreancy] = useState(
        (data && data.debts.isCreancy) || false
    );
    const [isCreancyDoc, setIsCreancyDoc] = useState(
        (data && data.debts.isCreancyDoc) || null
    );

    const checkInformation = () => {
        return true;
    };

    const _onValidation = () => {
        const res = checkInformation();
        if (res) {
            setData({
                ...data,
                ...{
                    debts: {
                        isDebt,
                        isDebtDoc,
                        isConsumerDebt,
                        isConsumerDebtDoc,
                        isCreancy,
                        isCreancyDoc,
                    },
                },
            });
            onClose();
        } else {
            setIsValidated(false);
        }
    };
    return (
        <>
            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <div className="flex">
                        <ToggleSwitch
                            checked={isDebt}
                            onChange={() => setIsDebt(!isDebt)}
                            label="Avez-vous des dettes ?"
                            disabled={isReadOnly}
                        />
                        <DocUpload
                            isReadOnly={isReadOnly}
                            show={isDebt}
                            value={isDebtDoc}
                            set={setIsDebtDoc}
                            uniqueId={`doc-upload-debt`}
                            title={
                                "Dossier de surrendettement, échéancier de dettes"
                            }
                        />
                    </div>
                </div>
            </div>
            {isDebt && (
                <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <div className="flex">
                            <ToggleSwitch
                                checked={isConsumerDebt}
                                onChange={() =>
                                    setIsConsumerDebt(!isConsumerDebt)
                                }
                                disabled={isReadOnly}
                                label="Avez-vous des crédits à la consommation ?"
                            />
                            <DocUpload
                                isReadOnly={isReadOnly}
                                show={isConsumerDebt}
                                value={isConsumerDebtDoc}
                                set={setIsConsumerDebtDoc}
                                title={"Echéancier des crédits"}
                                uniqueId={`doc-upload-debt-consumer`}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <div className="flex">
                        <ToggleSwitch
                            checked={isCreancy}
                            disabled={isReadOnly}
                            onChange={() => setIsCreancy(!isCreancy)}
                            label="Avez-vous des créances ?"
                        />
                        <DocUpload
                            isReadOnly={isReadOnly}
                            show={isCreancy}
                            value={isCreancyDoc}
                            set={setIsCreancyDoc}
                            title={"Reconnaissance de dette"}
                            uniqueId={`doc-upload-debt-creancy`}
                        />
                    </div>
                </div>
            </div>
            {!isReadOnly && (
                <div className="flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            )}
        </>
    );
};
