import React from "react";
import { useDropzone } from "react-dropzone";
import { getFile } from "../../../../../_api/S3";

const DropZoneArea: React.FC<{
    onDrop: any;
    accept?: any;
    open?: any;
    uploadedFiles?: File[];
    isReadOnly: boolean;
    onRemove?: (file: File) => void;
}> = (props) => {
    const { onDrop, accept, open, uploadedFiles, onRemove, isReadOnly } = props;
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            accept,
            onDrop,
            disabled: isReadOnly, // Désactive le dropzone si isReadOnly est true
        });

    const downloadFile = (path: string) => {
        getFile(path).then((resp) => {
            const blob = new Blob([resp.data], {
                type: resp.headers["content-type"],
            });

            const contentDisposition = resp.headers["content-disposition"];
            const fileName = contentDisposition
                ? contentDisposition.split("filename=")[1].replace(/"/g, "")
                : "downloaded-file"; // Valeur par défaut

            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName; // Définir le nom du fichier
            link.click(); // Déclencher le téléchargement

            URL.revokeObjectURL(link.href);
        });
    };
    return (
        <div>
            <div {...getRootProps({ className: "dropzone" })}>
                <input className="input-zone" {...getInputProps()} />
                {!isReadOnly && (
                    <div className="text-center">
                        <p className="dropzone-content">
                            Déposer les fichiers ici
                        </p>
                    </div>
                )}
                {uploadedFiles.length > 0 && (
                    <div className="mt-4">
                        <h4>Fichiers :</h4>
                        <ul>
                            {uploadedFiles.map((file, index) => {
                                return (
                                    <li key={index} className="file-item">
                                        <span className="file-item flex items-center justify-between w-full max-w-xs overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                                            {file.name || file.split("/").pop()}
                                        </span>

                                        {!isReadOnly && onRemove && (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onRemove(file);
                                                }}
                                                className="ml-2 text-red-600"
                                            >
                                                Retirer
                                            </button>
                                        )}
                                        {isReadOnly && (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    downloadFile(file);
                                                }}
                                                className="ml-2 text-red-600"
                                            >
                                                Télécharger
                                            </button>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DropZoneArea;
