import React from "react";

export const DocMJPM = () => {
    return (
        <>
            <iframe
                width={"100%"}
                height={"100%"}
                src="https://armoires.zeendoc.com/_Login/Login.php"
            ></iframe>
        </>
    );
};
