import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDepots } from "../../../../_api/Depot";
import Loader from "../../../layout/Loader";

const StatusControle = {
    SUCCESS: "Contrôle Valide",
    WAITING_DEPOT: "En attente de dépot",
    WAITING_VERIF: "En attente de votre controle",
    FAILED: "Contrôle Refusé",
    REVISION: "En attente de justificatif",
};

export const ListControls = () => {
    const { addToast } = useToasts();

    const [depots, setDepots] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getDepots(addToast).then((resp) => {
            setDepots(resp.data);
            setIsLoading(false);
        });
    }, []);

    const [selectedCategory, setSelectedCategory] = useState(""); // Filtre de catégorie
    const filteredData = selectedCategory
        ? depots.filter((item) => {
              return StatusControle[item.status] === selectedCategory;
          })
        : depots;

    const [currentPage, setCurrentPage] = useState(1); // Page actuelle
    const [itemsPerPage] = useState(12); // Nombre de lignes par page

    // Calculer le nombre total de pages
    const totalPages = Math.ceil(depots.length / itemsPerPage);

    // Déterminer les éléments à afficher pour la page actuelle
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1); // Reset page to 1 when filtering
    };

    // Récupérer les catégories uniques pour le filtre
    const uniqueCategories = [
        ...new Set(depots.map((item) => StatusControle[item.status])),
    ];

    return (
        <>
            <div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    {isLoading && (
                        <div className="flex items-center justify-center h-64">
                            <Loader />
                        </div>
                    )}
                    {!isLoading && (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Période de contrôle
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Numéro de RG
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Numéro de portalis
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Statuts
                                        <select
                                            className="ml-2 p-1 text-xs text-gray-700 bg-white border border-gray-300 rounded"
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                        >
                                            <option value="">Tous</option>
                                            {uniqueCategories.map(
                                                (category, index) => (
                                                    <option
                                                        key={index}
                                                        value={category}
                                                    >
                                                        {category}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Protégé
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Représentant
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {currentItems.map((item, index) => (
                                    <tr
                                        key={index}
                                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                    >
                                        <td className="px-6 py-4">
                                            {new Date(
                                                item.start_period
                                            ).toLocaleDateString()}{" "}
                                            -{" "}
                                            {new Date(
                                                item.end_period
                                            ).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.control.rg_number}
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.control.portalis_number}
                                        </td>

                                        <td className="px-6 py-4">
                                            {" "}
                                            {StatusControle[item.status]}
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.control.second_name}
                                        </td>
                                        <td className="px-6 py-4">
                                            {item.control.name ||
                                                "" +
                                                    " " +
                                                    item.control.tutor_name}
                                        </td>

                                        <td className="px-6 py-4">
                                            <Link
                                                to={"/controle/" + item._id}
                                                className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2"
                                            >
                                                Voir
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                {currentItems.length === 0 && (
                                    <tr className=" text-center odd:bg-white font-bold border-b dark:border-gray-700 h-12">
                                        <td colSpan={6}>
                                            Il n'y a actuellement aucun dépot de
                                            réaliser.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            className={`px-4 py-2 mx-1 text-sm font-medium text-white ${
                                currentPage === index + 1
                                    ? "bg-blue-500"
                                    : "bg-gray-700"
                            } rounded-md hover:bg-blue-700`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};
