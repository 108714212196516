import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Button } from "../../../../../_shared/Button";
import { DefaultDropdown } from "../../../../../_shared/Dropdown";
import { ToggleSwitch } from "../../../../../_shared/ToggleSwitch";
import { DocUpload } from "./DocUpload";

export const SituationPro = (props: {
    onClose: Function;
    setIsValidated: Function;
    isReadOnly: boolean;
    setData: Function;
    data: any;
}) => {
    const { onClose, setIsValidated, isReadOnly, setData, data } = props;
    const { addToast } = useToasts();

    const checkInformation = () => {
        if (rsa && !rsaDoc) {
            addToast(
                "Le rsa est rempli, il nous faut l'attestation de paiement.",
                { appeareance: "warning" }
            );
            return false;
        }

        if (aah && !aahDoc) {
            addToast(
                "AAH est rempli, il nous faut l'attestation de paiement.",
                { appeareance: "warning" }
            );
            return false;
        }

        if (pension && !pensionDoc) {
            addToast(
                "La pension est rempli, il nous faut l'attestation de paiement.",
                { appeareance: "warning" }
            );
            return false;
        }

        if (workAccident && !workAccidentDoc) {
            addToast(
                "L'indemnité de travail est rempli, il nous faut le relevé de paiement.",
                { appeareance: "warning" }
            );
            return false;
        }

        return true;
    };

    const [actifType, setActifType] = useState(
        (data && data.situation_pro.actifType) || "ACTIVE_PERSON"
    );

    const [proType, setProType] = useState(
        (data && data.situation_pro.proType) || "WORKER"
    );

    const [rsa, setRsa] = useState((data && data.situation_pro.rsa) || false);
    const [rsaDoc, setRsaDoc] = useState(
        (data && data.situation_pro.rsaDoc) || null
    );

    const [aah, setAah] = useState((data && data.situation_pro.aah) || null);
    const [aahDoc, setAahDoc] = useState(
        (data && data.situation_pro.aahDoc) || null
    );

    const [pension, setPension] = useState(
        (data && data.situation_pro.pension) || false
    );
    const [pensionDoc, setPensionDoc] = useState(
        (data && data.situation_pro.pensionDoc) || null
    );
    const [workAccident, setWorkAccident] = useState(
        (data && data.situation_pro.workAccident) || false
    );

    const [workAccidentDoc, setWorkAccidentDoc] = useState(
        (data && data.situation_pro.workAccidentDoc) || false
    );

    const _onValidation = () => {
        const res = checkInformation();
        if (res) {
            setData({
                ...data,
                ...{
                    situation_pro: {
                        actifType,
                        proType,
                        rsa,
                        rsaDoc,
                        aah,
                        aahDoc,
                        pension,
                        pensionDoc,
                        workAccident,
                        workAccidentDoc,
                    },
                },
            });

            onClose();
        } else {
            setIsValidated(false);
        }
    };

    useEffect(() => {}, [data]);

    return (
        <>
            <div className="mt-4 mb-4">
                <DefaultDropdown
                    name={"Situation professionnelle"}
                    options={[
                        { label: "Actif", value: "ACTIVE_PERSON" },
                        { label: "Retraité", value: "RETREAT_PERSON" },
                        { label: "Sans emploi", value: "WITHOUT_EMPLOYMENT" },
                    ]}
                    readOnly={isReadOnly}
                    value={actifType}
                    onChange={(e) => setActifType(e.target.value)}
                />
            </div>

            {actifType === "ACTIVE_PERSON" && (
                <DefaultDropdown
                    name={"Type"}
                    readOnly={isReadOnly}
                    options={[
                        { label: "Salarié", value: "WORKER" },
                        {
                            label: "Travailleur indépendant",
                            value: "INDEPENDANT",
                        },
                    ]}
                    value={proType}
                    onChange={(e) => setProType(e.target.value)}
                />
            )}
            {actifType === "WITHOUT_EMPLOYMENT" && (
                <>
                    <div className="flex flex-wrap -mx-3 mb-3 mt-4">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <div className="flex">
                                <ToggleSwitch
                                    checked={rsa}
                                    onChange={() => setRsa(!rsa)}
                                    label="RSA"
                                    disabled={isReadOnly}
                                />
                                <DocUpload
                                    isReadOnly={isReadOnly}
                                    show={rsa}
                                    value={rsaDoc}
                                    set={setRsaDoc}
                                    title={"Attestation de paiement"}
                                    uniqueId={`doc-upload-rsa`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <div className="flex">
                                <ToggleSwitch
                                    checked={aah}
                                    onChange={() => setAah(!aah)}
                                    label="AAH"
                                    disabled={isReadOnly}
                                />
                                <DocUpload
                                    isReadOnly={isReadOnly}
                                    show={aah}
                                    value={aahDoc}
                                    set={setAahDoc}
                                    title={"Attestation de paiement"}
                                    uniqueId={`doc-upload-aah`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <div className="flex">
                                <ToggleSwitch
                                    checked={pension}
                                    onChange={() => setPension(!pension)}
                                    label="Pension d'invalidité"
                                    disabled={isReadOnly}
                                />
                                <DocUpload
                                    isReadOnly={isReadOnly}
                                    show={pension}
                                    value={pensionDoc}
                                    set={setPensionDoc}
                                    title={"Attestation de paiement"}
                                    uniqueId={`doc-upload-pension`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <div className="flex">
                                <ToggleSwitch
                                    checked={workAccident}
                                    onChange={() =>
                                        setWorkAccident(!workAccident)
                                    }
                                    label="Indémnité accident du travail"
                                    disabled={isReadOnly}
                                />
                                <DocUpload
                                    isReadOnly={isReadOnly}
                                    show={workAccident}
                                    value={workAccidentDoc}
                                    set={setWorkAccidentDoc}
                                    title={"Relevé de paiement"}
                                    uniqueId={`doc-upload-workAccident`}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!isReadOnly && (
                <div className="flex justify-end mt-3">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            )}
        </>
    );
};
