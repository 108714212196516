import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { IntercomProvider } from "react-use-intercom";
import Layout from "./Layout";
import { UsePoller } from "./Pollers";
import CreateUpdateAccount from "./pages/Accounts/CreateUpdate";
import SingleAccount from "./pages/Accounts/SingleAccount";
import Alert from "./pages/Alerts/Alert";
import Alerts from "./pages/Alerts/Alerts";
import CreateUpdateAlert from "./pages/Alerts/CreateUpdate";
import Bank from "./pages/Banks/Bank";
import Banks from "./pages/Banks/Banks";
import CreateUpdateBank from "./pages/Banks/CreateUpdate";
import { CRGInvoicing } from "./pages/CRGSubmission/CRGInvoicing";
import { CRGControl } from "./pages/CRGSubmission/Control/CRGControl";
import { CreateControl } from "./pages/CRGSubmission/Control/CreateControl";
import { ListControls } from "./pages/CRGSubmission/Control/ListControls";
import { DepotCRG } from "./pages/CRGSubmission/Control/Segment/DepotCRG";
import { ListControlCRG } from "./pages/CRGSubmission/ListControlCRGfromHash";
import { CreateMandat } from "./pages/CRGSubmission/Mandat/CreateMandat";
import { ListMandats } from "./pages/CRGSubmission/Mandat/ListMandats";
import { SingleMandat } from "./pages/CRGSubmission/Mandat/SingleMandat";
import { SettingsCRG } from "./pages/CRGSubmission/SettingsCRG";
import Contact from "./pages/Contacts/Contact";
import Contacts from "./pages/Contacts/Contacts";
import CreateUpdateContact from "./pages/Contacts/CreateUpdate";
import Court from "./pages/Courts/Court";
import Courts from "./pages/Courts/Courts";
import CreateUpdateCourt from "./pages/Courts/CreateUpdate";
import Home from "./pages/Dashboard/Home";
import { DocMJPM } from "./pages/DocMJPM/DocMJPM";
import Documents from "./pages/Documents/Documents";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Login from "./pages/Login/Login";
import { LoginCRG } from "./pages/Login/LoginCRG";
import Register from "./pages/Login/Register";
import { RegisterCRG } from "./pages/Login/RegisterCRG";
import ResetPassword from "./pages/Login/ResetPassword";
import VerifyEmail from "./pages/Login/VerifyEmail";
import AuthProvider from "./pages/Login/auth.context";
import ProtectedRoute from "./pages/Login/routes";
import CreateUpdateLetter from "./pages/Maileva/CreateUpdateLetter";
import CreateUpdateRecipient from "./pages/Maileva/CreateUpdateRecipient";
import InfosRecipient from "./pages/Maileva/InfosRecipient";
import Letter from "./pages/Maileva/Letter";
import Letters from "./pages/Maileva/Letters";
import FormDebt from "./pages/Majeurs/Assets_Debts/FormDebt";
import FormImmobilier from "./pages/Majeurs/Assets_Debts/FormImmobilier";
import SingleDebt from "./pages/Majeurs/Assets_Debts/SingleDebt";
import SingleImmobilier from "./pages/Majeurs/Assets_Debts/SingleImmobilier";
import SingleMobilier from "./pages/Majeurs/Assets_Debts/SingleMobilier";
import CreateUpdateContract from "./pages/Majeurs/Contract_Droit/CreateUpdateContract";
import CreateUpdateSocial from "./pages/Majeurs/Contract_Droit/CreateUpdateSocial";
import SingleContract from "./pages/Majeurs/Contract_Droit/SingleContract";
import SingleSocial from "./pages/Majeurs/Contract_Droit/SingleSocial";
import FormCourrier from "./pages/Majeurs/Courrier/FormCourrier";
import SingleCourrier from "./pages/Majeurs/Courrier/SingleCourrier";
import CreateMajeur from "./pages/Majeurs/CreateMajeur";
import ListMajeurs from "./pages/Majeurs/ListMajeurs";
import CreateUpdateRessources from "./pages/Majeurs/Ressources/CreateUpdateRessources";
import SingleMajeur from "./pages/Majeurs/SingleMajeur";
import CreateUpdateSuivi from "./pages/Majeurs/Suivi/CreateUpdateSuivi";
import SingleSuivi from "./pages/Majeurs/Suivi/SingleSuivi";
import UpdateMajeur from "./pages/Majeurs/UpdateMajeur";
import CreateUpdateMeasure from "./pages/Mesures/CreateUpdate";
import News from "./pages/News/News";
import Settings from "./pages/Settings/Settings";
import UpdateInfoSettings from "./pages/Settings/UpdateInfosSettings";
import Statistics from "./pages/Statistics/Statistics";
import CreateUpdateTemplate from "./pages/TemplatesMail/CreateUpdate";
import Template from "./pages/TemplatesMail/Template";
import Templates from "./pages/TemplatesMail/Templates";
import CreateUpdateUser from "./pages/Users/CreateUpdate";
import User from "./pages/Users/User";
import Users from "./pages/Users/Users";

const RoutePath: React.FC = () => {
    const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;
    const user = sessionStorage.getItem("user");

    const INDEX_HTML_DEPLOYMENT_URL = process.env.REACT_APP_BASE_URL;

    const { isNewVersionAvailable } = UsePoller({
        deploymentUrl: INDEX_HTML_DEPLOYMENT_URL,
    });

    useEffect(() => {
        if (isNewVersionAvailable) {
            window.location.reload();
        }
    }, [isNewVersionAvailable]);

    return (
        <ToastProvider autoDismiss autoDismissTimeout={6000}>
            <AuthProvider user={JSON.parse(user)}>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                    <BrowserRouter>
                        <Switch>
                            {/* Routes refering to Login/Register/Passwords are not protected */}
                            <Route exact path="/login" component={Login} />
                            <Route
                                exact
                                path="/connexion"
                                component={LoginCRG}
                            />
                            <Route
                                exact
                                path="/enregistrement"
                                component={RegisterCRG}
                            />
                            <Route exact path="/" component={Login} />
                            <Route
                                exact
                                path="/register"
                                component={Register}
                            />

                            <Route
                                exact
                                path="/verify_email/:token"
                                component={VerifyEmail}
                            />
                            <Route
                                exact
                                path="/forgot_password"
                                component={ForgotPassword}
                            />
                            <Route
                                exact
                                path="/reset_password/:token"
                                component={ResetPassword}
                            />

                            <Route
                                exact
                                path="/controle/crg"
                                component={ListControlCRG}
                            />

                            <Route
                                exact
                                path="/verification/:depot_id"
                                component={DepotCRG}
                            />

                            <Layout
                                name={"ACCEL GERANCIA"}
                                color={"bg-appPrimary"}
                            >
                                <ProtectedRoute exact path="/dashboard">
                                    <Home />
                                </ProtectedRoute>

                                <ProtectedRoute exact path="/tribunaux">
                                    <Courts />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/tribunaux/nouveau">
                                    <CreateUpdateCourt />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/tribunaux/:courtId/modifier"
                                >
                                    <CreateUpdateCourt />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/tribunaux/:courtId/show"
                                >
                                    <Court />
                                </ProtectedRoute>
                                {/* Alerts */}
                                <ProtectedRoute exact path="/alertes">
                                    <Alerts />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/alertes/:alertId/modifier"
                                >
                                    <CreateUpdateAlert />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/alertes/nouvelle/:protectedAdultId"
                                >
                                    <CreateUpdateAlert />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/alertes/:alertId">
                                    <Alert />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/doc-mjpm">
                                    <DocMJPM />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/lettres">
                                    <Letters />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/lettres/nouvelle">
                                    <CreateUpdateLetter />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/lettres/:letterId/detail"
                                >
                                    <Letter />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/lettres/:letterId/modifier"
                                >
                                    <CreateUpdateLetter />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/lettres/:letterId/destinataire/ajouter"
                                >
                                    <CreateUpdateRecipient />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/lettres/:letterId/destinataire/:recipientId/modifier"
                                >
                                    <CreateUpdateRecipient />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/lettres/:letterId/destinataire/:recipientId/infos"
                                >
                                    <InfosRecipient />
                                </ProtectedRoute>
                                {/* Contacts */}
                                <ProtectedRoute exact path="/contacts">
                                    <Contacts />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/contacts/:contactId/modifier"
                                >
                                    <CreateUpdateContact />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/contacts/:contactId/modifier/:majeurId"
                                >
                                    <CreateUpdateContact />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/contacts/nouveau">
                                    <CreateUpdateContact />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/contacts/nouveau/:majeurId"
                                >
                                    <CreateUpdateContact />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/contacts/:contactId/show"
                                >
                                    <Contact />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/contacts/:contactId/show/:majeurId"
                                >
                                    <Contact />
                                </ProtectedRoute>
                                {/* Banks */}
                                <ProtectedRoute exact path="/banques">
                                    <Banks />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/banques/:bankId/modifier"
                                >
                                    <CreateUpdateBank />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/banques/nouvelle">
                                    <CreateUpdateBank />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/banques/:bankId/show"
                                >
                                    <Bank />
                                </ProtectedRoute>
                                {/* Documents */}
                                <ProtectedRoute exact path="/documents">
                                    <Documents />
                                </ProtectedRoute>
                                {/* News */}
                                <ProtectedRoute exact path="/news">
                                    <News />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/news/:newsId">
                                    <News />
                                </ProtectedRoute>
                                {/* Templates */}
                                <ProtectedRoute
                                    exact
                                    path="/list_templates_mail"
                                >
                                    <Templates />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/list_templates_mail/:templateId/lecture"
                                >
                                    <Template />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/list_templates_mail/:templateId/modifier"
                                >
                                    <CreateUpdateTemplate />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/list_templates_mail/nouveau"
                                >
                                    <CreateUpdateTemplate />
                                </ProtectedRoute>
                                {/* Statistics */}
                                <ProtectedRoute exact path="/statistiques">
                                    <Statistics />
                                </ProtectedRoute>
                                {/* Users */}
                                <ProtectedRoute exact path="/utilisateurs">
                                    <Users />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/utilisateurs/:userId/modifier"
                                >
                                    <CreateUpdateUser />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/utilisateurs/nouveaux"
                                >
                                    <CreateUpdateUser />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/utilisateurs/:userId/show"
                                >
                                    <User />
                                </ProtectedRoute>
                                {/* Settings */}
                                <ProtectedRoute exact path="/settings">
                                    <Settings />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/settings/:module">
                                    <Settings />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/update_infos_settings"
                                >
                                    <UpdateInfoSettings />
                                </ProtectedRoute>
                                {/* Proteges */}
                                <ProtectedRoute exact path="/list_majeurs">
                                    <ListMajeurs />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/create_majeur">
                                    <CreateMajeur />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_majeur/:majeurId"
                                >
                                    <SingleMajeur />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_majeur/:majeurId/:module"
                                >
                                    <SingleMajeur />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/update_majeur/:majeurId"
                                >
                                    <UpdateMajeur />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_account/:accountId"
                                >
                                    <SingleAccount />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_account/:accountId/:module"
                                >
                                    <SingleAccount />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/form_account/:accountId/modifier"
                                >
                                    <CreateUpdateAccount />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/form_account/:majeurId/nouveau"
                                >
                                    <CreateUpdateAccount />
                                </ProtectedRoute>
                                {/* Mesures */}
                                <ProtectedRoute
                                    exact
                                    path="/form_mesure/:majeurId"
                                >
                                    <CreateUpdateMeasure />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/form_mesure/:majeurId/:mesureId"
                                >
                                    <CreateUpdateMeasure />
                                </ProtectedRoute>
                                {/* Suivi */}
                                <ProtectedRoute
                                    exact
                                    path="/form_suivi/:majeurId"
                                >
                                    <CreateUpdateSuivi />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_suivi/:suiviID"
                                >
                                    <SingleSuivi />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/form_suivi/:majeurId/edit/:suivisId"
                                >
                                    <CreateUpdateSuivi />
                                </ProtectedRoute>
                                {/* Contract & Socials */}
                                {/* Contracts */}
                                <ProtectedRoute
                                    exact
                                    path="/form_contract/:majeurId/new"
                                >
                                    <CreateUpdateContract />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/form_contract/:majeurId/edit/:contractId"
                                >
                                    <CreateUpdateContract />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_contract/:contractId"
                                >
                                    <SingleContract />
                                </ProtectedRoute>
                                {/* SOCIAL */}
                                <ProtectedRoute
                                    exact
                                    path="/form_social/:majeurId"
                                >
                                    <CreateUpdateSocial />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/form_social/:majeurId/edit/:socialId"
                                >
                                    <CreateUpdateSocial />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_social/:socialId"
                                >
                                    <SingleSocial />
                                </ProtectedRoute>
                                {/* Courriers */}
                                <ProtectedRoute
                                    exact
                                    path="/single_mail/:courrierId"
                                >
                                    <SingleCourrier />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_mail/:majeurId/edit/:courrierId"
                                >
                                    <FormCourrier />
                                </ProtectedRoute>
                                {/* Immobilier et dettes */}
                                <ProtectedRoute
                                    exact
                                    path="/single_mobilier/:mobilierId"
                                >
                                    <SingleMobilier />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_immobilier/:immobilierId"
                                >
                                    <SingleImmobilier />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/single_debt/:debtId"
                                >
                                    <SingleDebt />
                                </ProtectedRoute>
                                {/* DEBT */}
                                <ProtectedRoute
                                    exact
                                    path="/form_debt/:majeurId/new"
                                >
                                    <FormDebt />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/form_debt/:majeurId/edit/:debtId"
                                >
                                    <FormDebt />
                                </ProtectedRoute>
                                {/* Immobilier & Mobilier */}
                                <ProtectedRoute
                                    exact
                                    path="/form_mob_immo/:majeurId/new/:type"
                                >
                                    <FormImmobilier />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/form_mob_immo/:majeurId/edit/:mobImmoId/:type"
                                >
                                    <FormImmobilier />
                                </ProtectedRoute>
                                {/* Ressource */}
                                <ProtectedRoute
                                    exact
                                    path="/update_ressources/:majeurId/:year"
                                >
                                    <CreateUpdateRessources />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/depot-crg">
                                    <DepotCRG />
                                </ProtectedRoute>

                                <ProtectedRoute
                                    exact
                                    path="/controle/:depot_id"
                                >
                                    <CRGControl />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/list_controls">
                                    <ListControls />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/list_mandats">
                                    <ListMandats />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/create_mandat">
                                    <CreateMandat />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/edit_mandat/:mandat_id"
                                >
                                    <CreateMandat />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/create_control/:mandat_id"
                                >
                                    <CreateControl />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/mandat/:controle_id"
                                >
                                    <SingleMandat />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    exact
                                    path="/depot-crg/:depot_id"
                                >
                                    <DepotCRG />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/list_invoices">
                                    <CRGInvoicing />
                                </ProtectedRoute>
                                <ProtectedRoute exact path="/settings_crg">
                                    <SettingsCRG />
                                </ProtectedRoute>
                            </Layout>
                        </Switch>
                    </BrowserRouter>
                </IntercomProvider>
            </AuthProvider>
        </ToastProvider>
    );
};

ReactDOM.render(<RoutePath />, document.getElementById("app"));

//export default RoutePath;
export default withRouter(RoutePath);
