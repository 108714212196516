import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { formatTelephone, momentUpdateLocale } from "../../../constants/Utils";
import IUser from "../../../interfaces/IUser";
import ItemTwoLines from "../../layout/ItemTwoLines";
import Loader from "../../layout/Loader";

const InfosSettings: React.FC<{ user: IUser }> = (props) => {
    momentUpdateLocale();

    return (
        <>
            <div className="infos_settings">
                <div className="subBar_actions sticky top-90 layout-space-between-center row">
                    <span className="subBar_actions_title">
                        Vos informations
                    </span>
                    <div className="subBar_actions_item">
                        <Link to="/update_infos_settings/" className="row">
                            <i className="material-icons">edit</i>
                        </Link>
                    </div>
                </div>

                {!props.user && <Loader />}
                <div className="page_content column">
                    {(props.user.type === "_ASSOCIATION_" ||
                        props.user.type === "_ETABLISSEMENT_") && (
                        <ItemTwoLines
                            title={
                                props.user.type === "_ASSOCIATION_"
                                    ? "Association"
                                    : "Etablissement"
                            }
                            content={
                                props.user.label_association || "Non renseigné."
                            }
                            flex="1"
                        />
                    )}
                    <div className="row layout-xs-column">
                        <ItemTwoLines
                            title="Civilité"
                            content={
                                props.user.profile.infos.civility ||
                                "Non renseigné."
                            }
                            flex="1"
                        />
                        <div className="flex005 hide-xs" />
                        <ItemTwoLines
                            title="Prénom"
                            content={
                                props.user.profile.infos.first_name ||
                                "Non renseigné."
                            }
                            flex="1"
                        />
                        <div className="flex005 hide-xs" />
                        <ItemTwoLines
                            title="Nom d'usage"
                            content={
                                props.user.profile.infos.last_name ||
                                "Non renseigné."
                            }
                            flex="1"
                        />
                        <div className="flex005 hide-xs" />
                        <ItemTwoLines
                            title="Nom de famille"
                            content={
                                props.user.profile.infos.family_name ||
                                "Non renseigné."
                            }
                            flex="1"
                        />
                    </div>
                    <div className="row layout-xs-column">
                        <ItemTwoLines
                            title="Date de naissance"
                            content={
                                props.user.profile.infos.birth_date
                                    ? moment(
                                          props.user.profile.infos.birth_date
                                      ).format("L")
                                    : "Non renseignée."
                            }
                            flex="1"
                            nodivider
                            dividerXs
                        />
                        <div className="flex005 hide-xs" />
                        <ItemTwoLines
                            title="Lieu de naissance"
                            content={
                                props.user.profile.infos.birth_location ||
                                "Non renseigné."
                            }
                            flex="1"
                            nodivider
                            dividerXs
                        />
                        <div className="flex005 hide-xs" />
                        <ItemTwoLines
                            title="Code postal de naissance"
                            content={
                                props.user.profile.infos
                                    .birth_location_zip_code || "Non renseigné."
                            }
                            flex="1"
                            nodivider
                        />
                    </div>
                    <div className="my-sub-title-form ">Coordonnées</div>
                    <ItemTwoLines
                        title="Adresse"
                        content={
                            props.user.profile.infos.address ||
                            "Non renseignée."
                        }
                    />
                    <div className="row layout-xs-column">
                        <ItemTwoLines
                            title="Code postal"
                            content={
                                props.user.profile.infos.zip_code ||
                                "Non renseigné."
                            }
                            flex="1"
                        />
                        <div className="flex005 hide-xs" />
                        <ItemTwoLines
                            title="Ville"
                            content={
                                props.user.profile.infos.city ||
                                "Non renseignée."
                            }
                            flex="1"
                        />
                    </div>
                    <div className="row layout-xs-column">
                        <ItemTwoLines
                            title="Fixe"
                            content={
                                formatTelephone(
                                    props.user.profile.infos.phone
                                ) || "Non renseigné."
                            }
                            flex="1"
                        />
                        <div className="flex005 hide-xs" />
                        <ItemTwoLines
                            title="Mobile"
                            content={
                                formatTelephone(
                                    props.user.profile.infos.mobile
                                ) || "Non renseigné."
                            }
                            flex="1"
                        />
                    </div>
                    <div className="row layout-xs-column">
                        <a href={`mailto:${props.user.emails[0].address}`}>
                            <ItemTwoLines
                                title="Email"
                                content={
                                    props.user.emails[0].address ||
                                    "Non renseigné."
                                }
                                flex="1"
                                nodivider
                            />
                        </a>
                        <div className="flex005 hide-xs" />
                        <ItemTwoLines
                            title="Fax"
                            content={
                                formatTelephone(props.user.profile.infos.fax) ||
                                "Non renseigné."
                            }
                            flex="1"
                            nodivider
                        />
                    </div>
                    {props.user.type !== "_ASSOCIATION_" &&
                        props.user.type !== "_ETABLISSEMENT_" &&
                        props.user.type !== "_FAMILLIALE_" && (
                            <>
                                <div className="my-sub-title-form">Statuts</div>

                                <div className="row">
                                    <ItemTwoLines
                                        title="Status"
                                        content={
                                            props.user.profile.status
                                                ? props.user.profile.status
                                                      .denomination ||
                                                  "Non renseigné"
                                                : "Non renseigné"
                                        }
                                        flex="1"
                                    />
                                </div>

                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Statuts"
                                        content={
                                            props.user.profile.status
                                                ? props.user.profile.status
                                                      .status || "Non renseigné"
                                                : "Non renseigné"
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Entité"
                                        content={
                                            props.user.profile.status.entite ||
                                            "Non renseignée"
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Siret"
                                        content={
                                            props.user.profile.status
                                                .n_sirene || "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Debut d'activité"
                                        content={
                                            props.user.profile.status
                                                .debut_activite
                                                ? moment(
                                                      props.user.profile.status
                                                          .debut_activite
                                                  ).format("L")
                                                : "Non renseignée."
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Code APE"
                                        content={
                                            props.user.profile.status
                                                .code_ape || "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="N° Agrément"
                                        content={
                                            props.user.profile.status
                                                .n_agrement || "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Lieu"
                                        content={
                                            props.user.profile.status.lieu ||
                                            "Non renseigné."
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Région"
                                        content={
                                            props.user.profile.status.region ||
                                            "Non renseignée."
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Département"
                                        content={
                                            props.user.profile.status
                                                .departement || "Non renseigné."
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                </div>
                                <div className="my-sub-title-form ">
                                    Condition d&quot;exercice
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Exerce seul l'activité"
                                        content={
                                            props.user.profile
                                                .condition_exercice.seul ||
                                            "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Estimation ETP"
                                        content={
                                            props.user.profile
                                                .condition_exercice
                                                .estimation_etp ||
                                            "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Nb dpt agrément"
                                        content={
                                            props.user.profile
                                                .condition_exercice
                                                .nb_departement_agrement ||
                                            "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Département du 1er agrément"
                                        content={
                                            props.user.profile
                                                .condition_exercice
                                                .DepartementPremierAgrement ||
                                            "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Secrétaires"
                                        content={
                                            props.user.profile
                                                .condition_exercice
                                                .secretaire || "Non renseigné."
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Nb de secrétaires"
                                        content={
                                            props.user.profile
                                                .condition_exercice
                                                .nb_secritaire
                                                ? props.user.profile
                                                      .condition_exercice
                                                      .nb_secritaire
                                                : "0"
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="ETP secrétaire"
                                        content={
                                            props.user.profile
                                                .condition_exercice
                                                .etp_secretaires
                                                ? props.user.profile
                                                      .condition_exercice
                                                      .etp_secretaires
                                                : "0"
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <div className="flex-1 hide-xs" />
                                </div>
                                <div className="my-sub-title-form ">
                                    Formation
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Année obtention CNC"
                                        content={
                                            props.user.profile.formation
                                                .annee_obtention_cnc ||
                                            "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Nb heures formation"
                                        content={
                                            props.user.profile.formation
                                                .nb_heure_formation ||
                                            "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Nv qualification mandataire"
                                        content={
                                            props.user.profile.formation
                                                .nv_qualification ||
                                            "Non renseigné."
                                        }
                                        flex="1"
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <ItemTwoLines
                                        title="Secrétaire Nv I"
                                        content={
                                            props.user.profile.formation
                                                .nb_niveauI || "0"
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Secrétaire Nv II"
                                        content={
                                            props.user.profile.formation
                                                .nb_niveauII || "0"
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Secrétaire Nv III"
                                        content={
                                            props.user.profile.formation
                                                .nb_niveauIII || "0"
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Secrétaire Nv IV"
                                        content={
                                            props.user.profile.formation
                                                .nb_niveauIV || "0"
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Secrétaire Nv V"
                                        content={
                                            props.user.profile.formation
                                                .nb_niveauV || "0"
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                    <div className="flex005 hide-xs" />
                                    <ItemTwoLines
                                        title="Secrétaire Nv VI"
                                        content={
                                            props.user.profile.formation
                                                .nb_niveauVI || "0"
                                        }
                                        flex="1"
                                        nodivider
                                    />
                                </div>
                            </>
                        )}
                </div>
            </div>
        </>
    );
};
export default InfosSettings;
