import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import {
    CIVILITY_OPTIONS,
    ENTITE_OPTIONS,
    ESTIMATION_OPTIONS,
    LIEU_OPTIONS,
    NB_DPT_AGREMENT_OPTIONS,
    QUALIFICATION_OPTIONS,
    SECRETAIRE_OPTIONS,
    SEUL_OPTIONS,
    STATUS_OPTIONS,
    capitalizeFirst,
    returnOnlyNumbers,
} from "../../../constants/Utils";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import ArrowBack from "../../layout/ArrowBack";
import InputComponent from "../../layout/forms/InputComponent";
import InputDatePicker from "../../layout/forms/InputDatePicker";
import Select from "../../layout/forms/Select";

const UpdateInfosSettings: React.FC = () => {
    const { addToast } = useToasts();

    const [user, setUser] = useState<IUser>(null);

    const [birthDate, setBirthDate] = useState<Date>();
    const [debutActivite, setDebutActivite] = useState<Date>();

    const [civilitySelected, setCivilitySelected] = useState<string>();
    const [status, setStatus] = useState<string>();
    const [entite, setEntite] = useState<string>();
    const [lieu, setLieu] = useState<string>();
    const [region, setRegion] = useState<string>("");
    const [departement, setDepartement] = useState<string>("");
    const [agrementDepartement, setAgrementDepartement] = useState<string>("");
    const [seul, setSeul] = useState<string>();
    const [secretaire, setSecretaire] = useState<string>();
    const [nbSecretaires, setNbSecretaires] = useState<string>("");
    const [etpSecretaire, setEtpSecretaire] = useState<string>("");
    const [secretaireNivI, setSecretaireNivI] = useState<string>("0");
    const [secretaireNivII, setSecretaireNivII] = useState<string>("0");
    const [secretaireNivIII, setSecretaireNivIII] = useState<string>("0");
    const [secretaireNivIV, setSecretaireNivIV] = useState<string>("0");
    const [secretaireNivV, setSecretaireNivV] = useState<string>("0");
    const [secretaireNivVI, setSecretaireNivVI] = useState<string>("0");
    const [anneeObtentionCNC, setAnneeObtentionCNC] = useState<string>("");
    const [nbHeuresFormation, setNbHeuresFormation] = useState<string>("0");
    const [estimation, setEstimation] = useState<string>();
    const [nbAgrement, setNbAgrement] = useState<string>();
    const [nbDptAgrement, setNbDptAgrement] = useState<string>("");
    const [codeApe, setCodeApe] = useState<string>("");
    const [denomination, setDenomination] = useState<string>("");
    const [qualification, setQualification] = useState<string>();
    const [labelAssociation, setLabelAssociation] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [familyName, setFamilyName] = useState<string>("");
    const [birthPlace, setBirthPlace] = useState<string>("");
    const [birthZipCode, setBirthZipCode] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [fixe, setFixe] = useState<string>("");
    const [mobile, setMobile] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [siret, setSiret] = useState<string>("");

    const history = useHistory();

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);
                    setBirthDate(
                        data.profile.infos.birth_date
                            ? data.profile.infos.birth_date.$date
                                ? new Date(
                                      parseInt(
                                          data.profile.infos.birth_date.$date
                                              .$numberLong
                                      )
                                  )
                                : data.profile.infos.birth_date
                            : null
                    );
                    setDebutActivite(
                        data.profile.status.debut_activite
                            ? data.profile.status.debut_activite.$date
                                ? new Date(
                                      parseInt(
                                          data.profile.status.debut_activite
                                              .$date.$numberLong
                                      )
                                  )
                                : data.profile.status.debut_activite
                            : null
                    );
                    setLabelAssociation(data.label_association ?? "");
                    setAnneeObtentionCNC(
                        data.profile.formation.annee_obtention_cnc ?? ""
                    );
                    setNbHeuresFormation(
                        data.profile.formation.nb_heure_formation ?? ""
                    );
                    setFirstName(data.profile.infos.first_name ?? "");
                    setLastName(data.profile.infos.last_name ?? "");
                    setFamilyName(data.profile.infos.family_name ?? "");
                    setBirthPlace(data.profile.infos.birth_location ?? "");
                    setBirthZipCode(
                        data.profile.infos.birth_location_zip_code ?? ""
                    );
                    setAddress(data.profile.infos.address ?? "");
                    setZipCode(data.profile.infos.zip_code ?? "");
                    setCity(data.profile.infos.city ?? "");
                    setFixe(data.profile.infos.phone ?? "");
                    setMobile(data.profile.infos.mobile ?? "");
                    setEmail(data.emails[0].address ?? "");
                    setFax(data.profile.infos.fax ?? "");
                    setCodeApe(data.profile.status.code_ape ?? "");
                    setDenomination(data.profile.status.denomination ?? "");
                    setSiret(data.profile.status.n_sirene ?? "");
                    setCivilitySelected(data.profile.infos.civility);
                    setStatus(data.profile.status.status ?? "");
                    setEntite(data.profile.status.entite ?? "");
                    setBirthDate(
                        data.profile.infos.birth_date
                            ? data.profile.infos.birth_date
                            : ""
                    );
                    setLieu(data.profile.status.lieu ?? "");
                    setRegion(data.profile.status.region ?? "");
                    setDepartement(data.profile.status.departement ?? "");
                    setAgrementDepartement(
                        data.profile.condition_exercice
                            .DepartementPremierAgrement ?? ""
                    );
                    setSeul(data.profile.condition_exercice.seul ?? "");
                    setSecretaire(
                        data.profile.condition_exercice.secretaire ?? ""
                    );
                    setNbSecretaires(
                        data.profile.condition_exercice.nb_secritaire ?? ""
                    );
                    setEtpSecretaire(
                        data.profile.condition_exercice.etp_secretaires ?? ""
                    );
                    setEstimation(
                        data.profile.condition_exercice.estimation_etp ?? ""
                    );
                    setQualification(
                        data.profile.formation.nv_qualification ?? ""
                    );
                    setNbDptAgrement(
                        data.profile.condition_exercice
                            .nb_departement_agrement ?? ""
                    );
                    setNbAgrement(data.profile.status.n_agrement ?? "");
                })
                .catch();
        }
    }, [user]);

    //Return _id from the actual route
    const updateInfosSettings = () => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        const userNewSettings: any = {
            userID: user._id,
            infos: {
                civility: civilitySelected,
                last_name: capitalizeFirst(lastName.trim()),
                first_name: capitalizeFirst(firstName.trim()),
                family_name: capitalizeFirst(familyName.trim()),
                birth_date: birthDate,
                birth_location: capitalizeFirst(birthPlace.trim()),
                birth_location_zip_code: birthZipCode.trim(),
                address: address.trim(),
                zip_code: zipCode.trim(),
                city: capitalizeFirst(city.trim()),
                phone: fixe.trim(),
                mobile: mobile.trim(),
                fax: fax === "" ? fax : fax.trim(),
                sponsor_id: user.profile.infos.sponsor_id ?? null,
            },
            status: {
                status: status,
                n_sirene: siret.trim(),
                lieu: lieu.trim(),
                code_ape: codeApe.trim(),
                denomination: denomination,
                entite: entite,
                debut_activite: debutActivite,
                departement: capitalizeFirst(departement),
                n_agrement: nbAgrement,
                region: region,
            },
            condition_exercice: {
                seul: seul,
                estimation_etp: estimation,
                secretaire: secretaire,
                nb_secritaire: nbSecretaires,
                etp_secretaires: etpSecretaire,
                nb_departement_agrement: nbDptAgrement,
                DepartementPremierAgrement: capitalizeFirst(
                    agrementDepartement.trim()
                ),
            },
            formation: {
                annee_obtention_cnc: anneeObtentionCNC,
                nb_heure_formation: nbHeuresFormation
                    ? parseInt(nbHeuresFormation)
                    : 0,
                nv_qualification: qualification,
                nb_niveauI: secretaireNivI ? parseInt(secretaireNivI) : 0,
                nb_niveauII: secretaireNivII ? parseInt(secretaireNivII) : 0,
                nb_niveauIII: secretaireNivIII ? parseInt(secretaireNivIII) : 0,
                nb_niveauIV: secretaireNivIV ? parseInt(secretaireNivIV) : 0,
                nb_niveauV: secretaireNivV ? parseInt(secretaireNivV) : 0,
                nb_niveauVI: secretaireNivVI ? parseInt(secretaireNivVI) : 0,
            },
        };

        // Push in userNewSettings labelAssociation if it is
        if (user.type === "_ASSOCIATION_" || user.type === "_ETABLISSEMENT_") {
            userNewSettings.label_association = labelAssociation.trim();
        }

        // Launch axios request to modify user here
        request({
            addToast,
            url: `/api/user/update`,
            method: "post",
            data: { data: userNewSettings },
        })
            .then((data) => {
                addToast("L'utilisateur a été modifié avec succès", {
                    appearance: "success",
                });
                history.push("/settings");
            })
            .catch();
    };

    return (
        <>
            {user && (
                <div>
                    <ArrowBack title="Modifier vos données" />
                    <div className="page_content">
                        <form>
                            <div className="page_content column">
                                {/* Informations */}
                                <div className="my-sub-title-form ">
                                    Vos informations
                                </div>
                                {(user.type === "_ASSOCIATION_" ||
                                    user.type === "_ETABLISSEMENT_") && (
                                    <InputComponent
                                        id="asso"
                                        type="text"
                                        className="my-input"
                                        label={
                                            user.type === "_ASSOCIATION_"
                                                ? "Association "
                                                : "Etablissement "
                                        }
                                        required
                                        value={labelAssociation}
                                        onChange={(e: any) =>
                                            setLabelAssociation(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                )}
                                <div className="row layout-xs-column">
                                    <div className="my-input-container flex-1">
                                        <Select
                                            value={civilitySelected}
                                            options={CIVILITY_OPTIONS}
                                            needDefaultValue={true}
                                            label="Civilité "
                                            required
                                            onChange={(e) =>
                                                setCivilitySelected(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="flex005 hide-xs"></div>
                                    <div className="flex-1 hide-xs"></div>
                                    <div className="flex005 hide-xs"></div>
                                    <div className="flex-1 hide-xs"></div>
                                </div>
                                <div className="row layout-xs-column">
                                    <InputComponent
                                        id="firstName"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Prénom "
                                        required
                                        value={firstName}
                                        onChange={(e: any) =>
                                            setFirstName(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                    <div className="flex005 hide-xs"></div>
                                    <InputComponent
                                        id="lastName"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Nom "
                                        required
                                        value={lastName}
                                        onChange={(e: any) =>
                                            setLastName(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                    <div className="flex005 hide-xs"></div>
                                    <InputComponent
                                        id="familyName"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Nom de famille "
                                        value={familyName}
                                        onChange={(e: any) =>
                                            setFamilyName(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                </div>
                                <div className="flex005 hide-xs"></div>
                                <div className="row layout-xs-column">
                                    <InputDatePicker
                                        id="label"
                                        inputDate={
                                            birthDate && new Date(birthDate)
                                        }
                                        label="Date de naissance "
                                        className="my-input flex-1"
                                        required
                                        onChange={(date: Date) =>
                                            setBirthDate(date)
                                        }
                                    />
                                    <div className="flex005 hide-xs"></div>
                                    <InputComponent
                                        id="birthPlace"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Lieu de naissance "
                                        value={birthPlace}
                                        onChange={(e: any) =>
                                            setBirthPlace(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                    <div className="flex005 hide-xs"></div>
                                    <InputComponent
                                        id="birthZipCode"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Code postal de naissance "
                                        value={birthZipCode}
                                        onChange={(e: any) =>
                                            setBirthZipCode(
                                                returnOnlyNumbers(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        maxLength={50}
                                    />
                                </div>

                                {/* Coordonnées */}
                                <div className="my-sub-title-form ">
                                    Coordonnées
                                </div>
                                <div className="my-input-container flex-1">
                                    <label htmlFor="address1">Adresse :</label>
                                    <textarea
                                        id="address1"
                                        value={address}
                                        placeholder="Adresse..."
                                        className="my-textarea"
                                        onChange={(e) =>
                                            setAddress(e.target.value)
                                        }
                                    ></textarea>
                                </div>
                                <div className="row layout-xs-column">
                                    <InputComponent
                                        id="zipCode"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Code postal "
                                        value={zipCode}
                                        onChange={(e) =>
                                            setZipCode(
                                                returnOnlyNumbers(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        maxLength={50}
                                    />
                                    <div className="flex005 hide-xs"></div>
                                    <InputComponent
                                        id="city"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Ville "
                                        value={city}
                                        onChange={(e) =>
                                            setCity(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <InputComponent
                                        id="fixe"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Fixe "
                                        value={fixe}
                                        onChange={(e) =>
                                            setFixe(
                                                returnOnlyNumbers(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        maxLength={50}
                                    />
                                    <div className="flex005 hide-xs"></div>
                                    <InputComponent
                                        id="mobile"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Mobile "
                                        value={mobile}
                                        onChange={(e) =>
                                            setMobile(
                                                returnOnlyNumbers(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        maxLength={50}
                                    />
                                </div>
                                <div className="row layout-xs-column">
                                    <InputComponent
                                        id="email"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Email "
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        maxLength={50}
                                    />
                                    <div className="flex005 hide-xs"></div>
                                    <InputComponent
                                        id="fax"
                                        type="text"
                                        className="my-input flex-1"
                                        label="Fax "
                                        value={fax}
                                        onChange={(e) =>
                                            setFax(
                                                returnOnlyNumbers(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        maxLength={50}
                                    />
                                </div>

                                {/* Status */}
                                {user.type !== "_ASSOCIATION_" &&
                                    user.type !== "_ETABLISSEMENT_" && (
                                        <>
                                            <div className="my-sub-title-form ">
                                                Statuts
                                            </div>
                                            <div className="row">
                                                <InputComponent
                                                    id="Denomination"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="Dénomination "
                                                    value={denomination}
                                                    onChange={(e) =>
                                                        setDenomination(
                                                            e.target.value
                                                        )
                                                    }
                                                    maxLength={100}
                                                />
                                            </div>

                                            <div className="row layout-xs-column">
                                                <div className="my-input-container flex-1">
                                                    <Select
                                                        value={status}
                                                        needDefaultValue={true}
                                                        options={STATUS_OPTIONS}
                                                        label="Status "
                                                        onChange={(e) =>
                                                            setStatus(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="flex005 hide-xs"></div>
                                                <div className="my-input-container flex-1">
                                                    <Select
                                                        value={entite}
                                                        needDefaultValue={true}
                                                        options={ENTITE_OPTIONS}
                                                        label="Entité "
                                                        onChange={(e) =>
                                                            setEntite(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="nSirene"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="Siret "
                                                    value={siret}
                                                    onChange={(e) =>
                                                        setSiret(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                            </div>

                                            <div className="row layout-xs-column">
                                                <InputDatePicker
                                                    id="debutActivite"
                                                    inputDate={
                                                        debutActivite &&
                                                        new Date(debutActivite)
                                                    }
                                                    label="Début d'activite "
                                                    className="my-input flex-1"
                                                    onChange={(date: Date) =>
                                                        setDebutActivite(date)
                                                    }
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="codeApe"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="Code APE "
                                                    value={codeApe}
                                                    onChange={(e) =>
                                                        setCodeApe(
                                                            e.target.value
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="nAgrement"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="N°Agrément "
                                                    value={nbAgrement}
                                                    onChange={(e) =>
                                                        setNbAgrement(
                                                            e.target.value
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                            </div>
                                            <div className="row layout-xs-column">
                                                <div className="my-input-container flex-1">
                                                    <Select
                                                        value={lieu}
                                                        needDefaultValue={true}
                                                        options={LIEU_OPTIONS}
                                                        label="Lieu "
                                                        onChange={(e) =>
                                                            setLieu(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="region"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="Région "
                                                    value={region}
                                                    onChange={(e) =>
                                                        setRegion(
                                                            e.target.value
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="departement"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="Departement "
                                                    value={departement}
                                                    onChange={(e) =>
                                                        setDepartement(
                                                            e.target.value
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                            </div>
                                        </>
                                    )}

                                {/* Status */}
                                {user.type !== "_ASSOCIATION_" &&
                                    user.type !== "_ETABLISSEMENT_" && (
                                        <>
                                            <div className="my-sub-title-form ">
                                                Condition d'exercice
                                            </div>
                                            <div className="row layout-xs-column">
                                                <div className="my-input-container flex-1">
                                                    <Select
                                                        value={seul}
                                                        needDefaultValue={true}
                                                        options={SEUL_OPTIONS}
                                                        label="Exerce seul activité "
                                                        onChange={(e) =>
                                                            setSeul(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="flex005 hide-xs"></div>
                                                <div className="my-input-container flex-1">
                                                    <Select
                                                        value={estimation}
                                                        needDefaultValue={true}
                                                        options={
                                                            ESTIMATION_OPTIONS
                                                        }
                                                        label="Estimation ETP "
                                                        onChange={(e) =>
                                                            setEstimation(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="flex005 hide-xs"></div>
                                                <div className="my-input-container flex-1">
                                                    <Select
                                                        value={nbDptAgrement}
                                                        needDefaultValue={true}
                                                        options={
                                                            NB_DPT_AGREMENT_OPTIONS
                                                        }
                                                        label="Nb dpt agrément "
                                                        onChange={(e) =>
                                                            setNbDptAgrement(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="departementPremierAgrement"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="Département du 1er agrément "
                                                    value={agrementDepartement}
                                                    onChange={(e) =>
                                                        setAgrementDepartement(
                                                            e.target.value
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                            </div>
                                            <div className="row layout-xs-column">
                                                <div className="my-input-container flex-1">
                                                    <Select
                                                        value={secretaire}
                                                        needDefaultValue={true}
                                                        options={
                                                            SECRETAIRE_OPTIONS
                                                        }
                                                        label="Secrétaire "
                                                        onChange={(e) =>
                                                            setSecretaire(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="nbSecretaires"
                                                    type="text"
                                                    className={
                                                        "my-input flex-1 " +
                                                        (secretaire != "OUI"
                                                            ? "disabled"
                                                            : "")
                                                    }
                                                    label="Nb de secrétaires "
                                                    value={nbSecretaires}
                                                    onChange={(e) =>
                                                        setNbSecretaires(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="etpSecretaire"
                                                    type="text"
                                                    className={
                                                        "my-input flex-1 " +
                                                        (secretaire != "OUI"
                                                            ? "disabled"
                                                            : "")
                                                    }
                                                    label="ETP secrétaire "
                                                    value={etpSecretaire}
                                                    onChange={(e) =>
                                                        setEtpSecretaire(
                                                            e.target.value
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <div className="flex-1 hide-xs"></div>
                                            </div>
                                        </>
                                    )}

                                {user.type !== "_ASSOCIATION_" &&
                                    user.type !== "_ETABLISSEMENT_" && (
                                        <>
                                            {/* Formation */}
                                            <div className="my-sub-title-form ">
                                                Formation
                                            </div>
                                            <div className="row layout-xs-column">
                                                <InputComponent
                                                    id="anneeObtentionCNC"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="Année obtention CNC "
                                                    value={anneeObtentionCNC}
                                                    onChange={(e) =>
                                                        setAnneeObtentionCNC(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="nbHeuresFormation"
                                                    type="text"
                                                    className="my-input flex-1"
                                                    label="Nb heures formation "
                                                    value={nbHeuresFormation}
                                                    onChange={(e) =>
                                                        setNbHeuresFormation(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <div className="my-input-container flex-1">
                                                    <Select
                                                        value={qualification}
                                                        needDefaultValue={true}
                                                        options={
                                                            QUALIFICATION_OPTIONS
                                                        }
                                                        label="Nv qualification mandataire "
                                                        onChange={(e) =>
                                                            setQualification(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row layout-xs-column">
                                                <InputComponent
                                                    id="niveauI"
                                                    type="text"
                                                    className={
                                                        "my-input flex-1 " +
                                                        (secretaire != "OUI"
                                                            ? "disabled"
                                                            : "")
                                                    }
                                                    label="Secrétaire Nv I "
                                                    value={secretaireNivI}
                                                    onChange={(e) =>
                                                        setSecretaireNivI(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="niveauII"
                                                    type="text"
                                                    className={
                                                        "my-input flex-1 " +
                                                        (secretaire != "OUI"
                                                            ? "disabled"
                                                            : "")
                                                    }
                                                    label="Secrétaire Nv II "
                                                    value={secretaireNivII}
                                                    onChange={(e) =>
                                                        setSecretaireNivII(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="niveauIII"
                                                    type="text"
                                                    className={
                                                        "my-input flex-1 " +
                                                        (secretaire != "OUI"
                                                            ? "disabled"
                                                            : "")
                                                    }
                                                    label="Secrétaire Nv III "
                                                    value={secretaireNivIII}
                                                    onChange={(e) =>
                                                        setSecretaireNivIII(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="niveauIV"
                                                    type="text"
                                                    className={
                                                        "my-input flex-1 " +
                                                        (secretaire != "OUI"
                                                            ? "disabled"
                                                            : "")
                                                    }
                                                    label="Secrétaire Nv IV "
                                                    value={secretaireNivIV}
                                                    onChange={(e) =>
                                                        setSecretaireNivIV(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="niveauV"
                                                    type="text"
                                                    className={
                                                        "my-input flex-1 " +
                                                        (secretaire != "OUI"
                                                            ? "disabled"
                                                            : "")
                                                    }
                                                    label="Secrétaire Nv V "
                                                    value={secretaireNivV}
                                                    onChange={(e) =>
                                                        setSecretaireNivV(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                                <div className="flex005 hide-xs"></div>
                                                <InputComponent
                                                    id="niveauVI"
                                                    type="text"
                                                    className={
                                                        "my-input flex-1 " +
                                                        (secretaire != "OUI"
                                                            ? "disabled"
                                                            : "")
                                                    }
                                                    label="Secrétaire Nv VI "
                                                    value={secretaireNivVI}
                                                    onChange={(e) =>
                                                        setSecretaireNivVI(
                                                            returnOnlyNumbers(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    maxLength={50}
                                                />
                                            </div>
                                        </>
                                    )}
                            </div>

                            <div className="column">
                                <button
                                    type="button"
                                    className="valid-button"
                                    onClick={() => updateInfosSettings()}
                                >
                                    Modifier
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};
export default UpdateInfosSettings;
