import React from "react";
import { AlertMessage } from "../../../../_shared/AlertMessage";

export const PreControl = () => {
    return (
        <>
            <AlertMessage message="En cours de développement" type="info" />
            {/* <ul>
                <li>
                    - justificatifs des revenus ou allocations perçus pendant
                    l’année ;
                </li>
                <li>
                    - justificatifs des impôts et taxes payés dans l’année, ou
                    avis de non-imposition ;
                </li>{" "}
                <li>
                    - ensemble des relevés de comptes de la période pour chacun
                    des comptes, livrets, titres ou contrats mentionnés dans le
                    compte de gestion ;
                </li>{" "}
                <li>
                    - résultat de l’interrogation des fichiers Ficoba et Ficovie
                    le cas échéant ;
                </li>
                <li> - justificatifs des dépenses supérieures à 500 euros ;</li>
                <li>
                    {" "}
                    - ordonnances du juge des tutelles relatives au patrimoine
                    de la personne protégée rendues dans l’année
                </li>
            </ul> */}
        </>
    );
};
