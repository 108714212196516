import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { checkHashFromLetter } from "../../../_api/Mandat";
import { AlertMessage } from "../../../_shared/AlertMessage";
import { Button } from "../../../_shared/Button";
import { InputForm } from "../../../_shared/InputField";

export const ListControlCRG = () => {
    const { addToast } = useToasts();
    const history = useHistory();

    const [hash, setHash] = useState();
    const [validHash, setValidHash] = useState(false);
    const [currentControls, setCurrentControls] = useState([]);

    const isValidHash = () => {
        checkHashFromLetter(addToast, hash).then((resp) => {
            setValidHash(resp.data.validated);
            setCurrentControls(resp.data.controls);
        });
    };

    console.log(validHash, currentControls);
    return (
        <div className="flex h-screen bg-gray-100">
            <div className="w-1/2 relative">
                <img
                    id="logo"
                    src={"/imgs/logo_crg.webp"}
                    alt="Chateau"
                    className="w-full h-full object-cover"
                />

                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center p-10 text-white">
                    <h1 className="text-4xl font-bold">
                        Contrôle de Compte Rendu de Gestion (CRG)
                    </h1>
                    {/* <p className="text-lg mt-4">Par Accel Gerancia</p> */}
                </div>
            </div>
            {!validHash && (
                <div className="w-1/2 flex justify-center items-center">
                    <div className="bg-white p-10 rounded-lg shadow-md w-3/4 max-w-md">
                        <div className="space-y-4">
                            <h3 className="text-center">
                                Contrôle de Compte Rendu de Gestion (CRG)
                            </h3>
                            <InputForm
                                name="Code fourni par courrier"
                                set={setHash}
                                value={hash}
                            />
                            <div className="flex justify-end">
                                <Button
                                    action={() => isValidHash()}
                                    name={"Accéder à mon contrôle"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {validHash && (
                <div className="w-1/2 flex justify-center items-center">
                    <div className="bg-white p-10 rounded-lg shadow-md w-3/4 max-w-md">
                        <div className="space-y-4">
                            <h3 className="text-center">
                                Choisir l'année de mon contrôle :
                            </h3>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                                {currentControls.map((control) => (
                                    <div
                                        key={control._id}
                                        className="cursor-pointer bg-white shadow-lg rounded-lg p-6 border border-gray-200"
                                        onClick={() =>
                                            history.push(
                                                "/verification/" + control._id
                                            )
                                        }
                                    >
                                        {/* <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                            {control.name}
                                        </h3> */}
                                        c
                                        <div className="mt-4 bg-blue-50 border border-blue-300 rounded-md p-4">
                                            {/* <p className="text-sm text-blue-700">
                                                Période de contrôle :
                                            </p> */}
                                            <p className="text-center text-lg font-semibold text-blue-900">
                                                {new Date(
                                                    control.start_period
                                                ).toLocaleDateString()}{" "}
                                                -{" "}
                                                {new Date(
                                                    control.end_period
                                                ).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {currentControls.length === 0 && (
                                <AlertMessage
                                    type="info"
                                    message="Il n'y a aucun contrôle pour l'instant."
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
