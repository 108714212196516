import React, { useState } from "react";
import { Button } from "../../../../../_shared/Button";
import { DocUpload } from "./DocUpload"; // Assurez-vous que le chemin est correct

export const Patrimoine = (props: {
    onClose: any;
    setIsValidated: Function;
    isReadOnly: boolean;
    setData: Function;
    data: any;
}) => {
    const { onClose, setIsValidated, isReadOnly, setData, data } = props;

    const [biens, setBiens] = useState((data && data.patrimoine.biens) || []);
    const [biensMobiliers, setBiensMobiliers] = useState([]);

    const [formData, setFormData] = useState({
        nature: "",
        etat: "vacant",
        adresse: "",
        loyer: "",
        assurance: "non",
        taxe: null, // Fichier taxe foncière
        releve: null, // Fichier relevé de gestion locative
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAddBien = () => {
        if (
            !formData.nature ||
            !formData.adresse ||
            !formData.loyer ||
            isNaN(Number(formData.loyer))
        ) {
            alert("Veuillez remplir correctement tous les champs.");
            return;
        }
        setBiens([...biens, { ...formData, taxe: null, releve: null }]);
        setFormData({
            nature: "",
            etat: "vacant",
            adresse: "",
            taxe: null, // Ajout des champs pour les fichiers
            releve: null,
            loyer: "",
            assurance: "non",
        });
    };

    const handleDeleteBien = (index) => {
        const updatedBiens = biens.filter((_, i) => i !== index);
        setBiens(updatedBiens);
    };

    const checkInformation = () => {
        return true;
    };

    const _onValidation = () => {
        const res = checkInformation();
        if (res) {
            setData({
                ...data,
                ...{
                    patrimoine: {
                        biens,
                        biensMobiliers,
                    },
                },
            });
            onClose();
        } else {
            setIsValidated(false);
        }
    };

    const handleFileUpload = (index, fileType, file) => {
        setBiens((prevBiens) => {
            const updatedBiens = prevBiens.map((bien, i) => {
                if (i === index) {
                    return { ...bien, [fileType]: file }; // Met à jour uniquement le fichier concerné
                }
                return bien; // Les autres restent inchangés
            });
            return updatedBiens;
        });
    };

    return (
        <>
            <div className="flex flex-wrap mb-3 mt-4">
                <div className="w-full md:w-1/2">
                    <h1 className="text-2xl font-bold mb-4">
                        Biens immobiliers hors résidence principale
                    </h1>

                    <div className="flex flex-wrap mb-3 mt-4">
                        {/* Formulaire */}
                        {!isReadOnly && (
                            <div className="w-full md:w-1/2 bg-white shadow rounded-lg p-6 mb-6">
                                <h2 className="text-lg font-semibold mb-4">
                                    Ajouter un Bien
                                </h2>
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Nature du bien
                                        </label>
                                        <select
                                            name="nature"
                                            value={formData.nature}
                                            onChange={handleChange}
                                            className="w-full border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        >
                                            <option value="">
                                                -- Choisir --
                                            </option>
                                            <option value="appartement">
                                                Appartement
                                            </option>
                                            <option value="maison">
                                                Maison
                                            </option>
                                            <option value="terrain">
                                                Terrain
                                            </option>
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            État
                                        </label>
                                        <div className="flex items-center space-x-4">
                                            <label className="flex items-center">
                                                <input
                                                    type="radio"
                                                    name="etat"
                                                    value="vacant"
                                                    checked={
                                                        formData.etat ===
                                                        "vacant"
                                                    }
                                                    onChange={handleChange}
                                                    className="text-blue-500"
                                                />
                                                <span className="ml-2">
                                                    Vacant
                                                </span>
                                            </label>
                                            <label className="flex items-center">
                                                <input
                                                    type="radio"
                                                    name="etat"
                                                    value="occupé"
                                                    checked={
                                                        formData.etat ===
                                                        "occupé"
                                                    }
                                                    onChange={handleChange}
                                                    className="text-blue-500"
                                                />
                                                <span className="ml-2">
                                                    Occupé
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Adresse
                                        </label>
                                        <input
                                            type="text"
                                            name="adresse"
                                            value={formData.adresse}
                                            onChange={handleChange}
                                            className="w-full border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Entrez l'adresse"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Montant du loyer mensuel (€)
                                        </label>
                                        <input
                                            type="number"
                                            name="loyer"
                                            value={formData.loyer}
                                            onChange={handleChange}
                                            className="w-full border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Exemple : 750"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Assurance propriétaire non occupant
                                        </label>
                                        <select
                                            name="assurance"
                                            value={formData.assurance}
                                            onChange={handleChange}
                                            className="w-full border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        >
                                            <option value="non">Non</option>
                                            <option value="oui">Oui</option>
                                        </select>
                                    </div>
                                </div>

                                <button
                                    onClick={handleAddBien}
                                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600"
                                >
                                    Ajouter à la liste
                                </button>
                            </div>
                        )}

                        {/* Liste des biens */}
                        <div className="w-full md:w-1/2 bg-white shadow rounded-lg p-6">
                            <h2 className="text-lg font-semibold mb-4">
                                Liste des Biens
                            </h2>
                            {biens.length > 0 ? (
                                <ul className="space-y-4">
                                    {biens.map((bien, index) => (
                                        <li
                                            key={index}
                                            className="border rounded-lg p-4 bg-gray-50 shadow-sm"
                                        >
                                            <p>
                                                <strong>Nature :</strong>{" "}
                                                {bien.nature}
                                            </p>
                                            <p>
                                                <strong>État :</strong>{" "}
                                                {bien.etat}
                                            </p>
                                            <p>
                                                <strong>Adresse :</strong>{" "}
                                                {bien.adresse}
                                            </p>
                                            <p>
                                                <strong>Loyer :</strong>{" "}
                                                {bien.loyer} €
                                            </p>
                                            <p>
                                                <strong>Assurance :</strong>{" "}
                                                {bien.assurance === "oui"
                                                    ? "Oui"
                                                    : "Non"}
                                            </p>
                                            <DocUpload
                                                uniqueId={`doc-upload-${index}`}
                                                isReadOnly={isReadOnly}
                                                key={`taxe-${index}`} // Une clé unique pour chaque tax
                                                show={true}
                                                value={bien.taxe}
                                                set={(file) => {
                                                    handleFileUpload(
                                                        index,
                                                        "taxe",
                                                        file
                                                    );
                                                }}
                                                title="Taxe foncière"
                                            />
                                            <DocUpload
                                                uniqueId={`doc-upload-releve-${index}`}
                                                key={`releve-${index}`} // Une clé unique pour chaque tax
                                                show={true}
                                                isReadOnly={isReadOnly}
                                                value={bien.releve}
                                                set={(file) => {
                                                    handleFileUpload(
                                                        index,
                                                        "releve",
                                                        file
                                                    );
                                                }}
                                                title="Relevé de gestion"
                                            />

                                            {!isReadOnly && (
                                                <button
                                                    onClick={() =>
                                                        handleDeleteBien(index)
                                                    }
                                                    className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600"
                                                >
                                                    Supprimer
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-gray-500">
                                    Aucun bien ajouté pour le moment.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {!isReadOnly && (
                <div className="flex justify-end">
                    <Button name={"Suivant"} action={() => _onValidation()} />
                </div>
            )}
        </>
    );
};
