import axios from "axios";
import { request } from "../utils/axios-utils";

const namespace = "/api/user";

export const getControls = (addToast: any) => {
    return request({
        addToast,
        url: namespace + "/controls",
        method: "get",
    });
};

export const getControl = (addToast: any, controle_id: any) => {
    return request({
        addToast,
        url: namespace + "/control/" + controle_id,
        method: "get",
    });
};

export const createControl = (addToast: any, payload: any) => {
    return request({
        addToast,
        url: namespace + `/control`,
        method: "post",
        data: payload,
    });
};

export const uploadJudgement = (
    addToast: any,
    formData: any,
    mandat_id: string
) => {
    return request({
        addToast,
        url: namespace + `/control/judgment/` + mandat_id,
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data", // Important pour les fichiers
        },
    });
};

export const editControl = (addToast: any, payload: any, mandat_id: string) => {
    return request({
        addToast,
        url: namespace + `/control/` + mandat_id,
        method: "put",
        data: payload,
    });
};

export const downloadNotices = (type: string, depot_id: any) => {
    return axios.get(
        namespace + "/control/" + depot_id + "/" + type + "/download_notice",
        {
            responseType: "blob",
        }
    );
};

export const uploadInformations = (addToast, formData, _id) => {
    return request({
        addToast,
        url: namespace + `/control/infos/` + _id,
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data", // Important pour les fichiers
        },
    });
};

export const checkHashFromLetter = (addToast, hash) => {
    return request({
        addToast,
        url: "/api/user/check_hash/" + hash,
        method: "get",
    });
};

export const changeStatusMandat = (addToast, mandat_id: string, payload) => {
    return request({
        addToast,
        url: namespace + "/mandat/status/" + mandat_id,
        method: "post",
        data: payload,
    });
};

export const addEmailToMandat = (
    addToast: any,
    depot_id: string,
    payload: object
) => {
    return request({
        addToast,
        url: namespace + "/mandat/add_email/" + depot_id,
        method: "post",
        data: payload,
    });
};
