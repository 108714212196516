import PropTypes from "prop-types";
import React from "react";

export const AlertMessage = (props: { type: string; message: string }) => {
    const { message, type } = props;
    const getClassName = () => {
        switch (type) {
            case "success":
                return "text-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400";
            case "warning":
                return "text-center p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300";
            case "info":
                return "text-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400";
            case "error":
                return "text-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400";
            default:
                return "text-center p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300";
        }
    };

    return (
        <div className={getClassName()}>
            <span className="font-bold">{message}</span>
        </div>
    );
};

AlertMessage.propTypes = {
    type: PropTypes.oneOf(["success", "warning", "alert"]).isRequired,
    message: PropTypes.string.isRequired,
};
