import React from "react";
import { AlertMessage } from "../../../../_shared/AlertMessage";

export const CourrierTab = () => {
    return (
        <>
            {" "}
            <AlertMessage message="En cours de développement" type="info" />
        </>
    );
};
